import styled from "styled-components";

export const Container = styled.div`
    margin-top: 1rem;
    /* width: 270px; */
`;

export const Content  = styled.div`
    padding: 1rem;
    padding-top: 0;
    
    .content-card-collect {
        font-weight: 400;
        font-family: 'Jost', sans-serif;
        font-size: 0.9rem;
        color: gray;
        margin-bottom: -0.5rem;
    }

    p {
        font-weight: 500;
        font-family: 'Jost', sans-serif;
        font-size: 1.2rem;
        color: #1C4738;
        margin-top: 0.5rem;
    }

    .date-content-card-collect {
        margin-top: -1rem;
        color: #1C4738;
        font-weight: 600;
        font-size: 1.5rem;
    }

    .infos-card-collect {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: -1.5rem;
        p {
            font-weight: 300;
            font-size: 1rem;
            margin-top: 0;
        }

        span {
            font-weight: 500;
        }

        .status-card-collect {
            margin-left: 0.5rem;
            margin-top: -0.8rem;
            p {
                font-weight: 500;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                font-size: 0.8rem;
                margin-top: 0;
            }
        }
    }

    .bottom-card-collect {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
        p {
            font-weight: 500;
            font-family: 'Jost', sans-serif;
            font-size: 0.7rem;
            color: #1C4738;
            align-items: center;
            margin-top: 0.7rem;
        }
        .icon-bottom-card-collect {
            font-size: 1.2rem;
        }
    }

`;

export const Underline = styled.div`
    width: 100%;
    height: 2px;
    background-color: #BEC7C7;
    margin-top: -0.3rem;
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: row;
    p{
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738; 
    }
`;

export const RowModal = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: -1.5rem;
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;
        
        span {
            font-family: 'Jost', sans-serif;
            font-weight: 400;
            color: #1C4738;
        }
    }
`;