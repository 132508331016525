import { DataGrid } from 'devextreme-react';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 30px;
    .lottie{
        height: 75vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .maps-list-users {
        padding: 30px;
    }
    .dx-datagrid-search-panel 
    .dx-show-invalid-badge 
    .dx-textbox 
    .dx-texteditor 
    .dx-editor-filled 
    .dx-searchbox 
    .dx-show-clear-button 
    .dx-widget 
    .dx-texteditor-empty {
        font-family: 'Jost', sans-serif;
        color: #1C4738;
    }
    .dx-texteditor-input-container {
        font-family: 'Jost', sans-serif;
        color: #1C4738;
    }
`;

export const Header = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .buttons-head-users {
        margin-right: 0.5rem;
    }
`;

export const TextHeader = styled.p`
    margin: 0;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    color: #1C4738;
    margin-right: 0.5rem;
`;

export const Table = styled(DataGrid).attrs({
    keyExpr:"id",
    showColumnLines: false,
})`
    padding: 0;
    font-family: 'Jost', sans-serif;
    color: #1C4738;
    
    .dx-datagrid .dx-row > td{
        padding-top: 0;
        padding-bottom: 0;
    }
    .dx-datagrid-headers .dx-row > td{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    
`;

export const PhotoProfile = styled.img`
    width: 40px;
    height: 40px;
    margin: 0;
    border-radius: 20px;
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738; 
    }

    .div-photo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .user-avatar {
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
    }
`;

export const RowModal = styled.div`
    margin-bottom: -1.5rem;
    
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;
        
        span {
            font-family: 'Jost', sans-serif;
            font-weight: 400;
            color: #1C4738;
        }
    }
`;