import styled from "styled-components";
import { css } from "styled-components";

export const Container = styled.div`
    padding: 0 20px;

    & .card-users {
        background-color: #F0F0EB;
        margin-bottom: 20px;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    & .content-photo {
        width: 60px;
        height: 60px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 2rem;
        }
    }

    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        color: #1C4738;
        align-items: center;
        margin-right: 20px;
        padding: 0;
    }

    h4 {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        color: #1C4738;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2rem;
    }
`;

export const Active = styled.div`
    ${(props) =>
        props?.active === 1 &&
        css`
            background-color: #3EB595;
            color: #fff;
            height: 1rem;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 12px;
            border-radius: 0.25rem;
            font-family: 'Jost', sans-serif;
            font-weight: 400;
        `
    }
    ${(props) =>
        props?.active === 0 &&
        css`
            background-color: gray;
            color: #fff;
            height: 1rem;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 12px;
            border-radius: 0.25rem;
            font-family: 'Jost', sans-serif;
            font-weight: 400;
        `
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;
        margin-top: 5px;
        margin-bottom: 5px
    }
    & .date-created-last-users {
        margin-left: 0.2rem;
    }
    & .address-last-users {
        margin-right: 0.2rem;
    }
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738; 
    }

    .div-photo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .user-avatar {
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
    }
`;

export const RowModal = styled.div`
    margin-bottom: -1.5rem;
    
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;
        
        span {
            font-family: 'Jost', sans-serif;
            font-weight: 400;
            color: #1C4738;
        }
    }
`;