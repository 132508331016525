import React, { useState, useCallback, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button, CheckBox, LoadPanel, Popup, Toast } from 'devextreme-react';
import LoadIndicator from 'devextreme-react/load-indicator';
import ButtonOptions from 'devextreme-react/button';

import { InputForm, InputFormCpf, InputFormMask } from '../InputForm';
import api from '../../services/api';

import './create-account-form.scss';
import { Container, ContentModalSuccess } from './styles';
import logo from '../../assets/uzehnewlogowhite.svg';
import { RiCloseLine } from 'react-icons/ri';
import { IoMdCloseCircle } from 'react-icons/io';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useAuth } from '../../contexts/auth';

export default function CreateAccountForm(props) {
  const history = useHistory();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [type, setType] = useState('');
  const [situation, setSituation] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false)
  const [terms, setTerms] = useState(false);

  const { signIn } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true)
    }, '2000');
    return () => clearTimeout(timer);
  }, [])

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);

    if (firstPassword === secondPassword) {
      try {
        const response = await api.post('/users', {
          email,
          name,
          lastname: lastName,
          password: firstPassword,
          phone,
          document: cpf,
          origin: 'web',
          user_type_id: 3
        })

        setLoading(false);
        setModalSuccess(true)

      } catch (e) {
        switch (e.response.data.message) {
          case 'Email/phone already exists':
            emailOrPhoneExists('email')
            setLoading(false);
            break;
          default:
            signInFail('fail')
            setLoading(false);
        }
      }
    } else {
      passwordNoEqual('password')
      setLoading(false);
    }
  }, [email, name, lastName, firstPassword, secondPassword, phone, cpf, history]);

  const onLogin = useCallback(async () => {
    const result = await signIn(email, firstPassword);
    history.push('/home');
  }, [email, firstPassword])

  function signInFail(situation) {
    setToast(true)
    setType('error')
    setSituation(situation)
  }

  function emailOrPhoneExists(situation) {
    setToast(true)
    setType('error')
    setSituation(situation)
  }

  function passwordNoEqual(situation) {
    setToast(true)
    setType('error')
    setSituation(situation)
  }

  function onCloseModal() {
    setModal(false)
  }

  function onCloseToast() {
    setToast(false)
  }

  function onCloseModalSuccess() {
    setModalSuccess(false)
  }

  return (
    <Container>
      {loading ?
        <LoadPanel visible={true} />
        :
        <>
          <form className={'create-account-form'} onSubmit={onSubmit}>
            <p>Realize seu cadastro para poder cadastrar Ecopontos.</p>
            <section>
              <InputForm label='Nome:' name='name' htmlFor='name' required={true} type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
              <InputForm label='Sobrenome:' name='lastname' htmlFor='lastname' required={true} type='text' value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
              <div className='div-cpf-phone'>
                <InputFormCpf label='CPF ou CNPJ:' name='cpf' htmlFor='cpf' required={true} type='tel' value={cpf} onChange={(e) => { setCpf(e.target.value.replace(/\D/g, "")) }} />
                <InputFormMask label='Nº Celular:' name='phone' htmlFor='phone' required={true} type='text' mask='(99)99999-9999' value={phone} onChange={(e) => { setPhone(e.target.value.replace(/\D/g, "")) }} />
              </div>
              <InputForm label='E-mail:' name='email' htmlFor='email' required={true} type='email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
              <div className='div-password'>
                <InputForm style={{ fontSize: '2rem' }} pattern="[0-9]+$" label='Senha com 6 números:' minLength='6' maxLength='6' inputMode='number' name='firstPassword' htmlFor='firstPassword' required={true} type='password' value={firstPassword} onChange={(e) => { setFirstPassword(e.target.value) }} />
                <InputForm style={{ fontSize: '2rem' }} pattern="[0-9]+$" label='Confirme senha:' minLength='6' maxLength='6' inputMode='number' name='secondPassword' htmlFor='secondPassword' required={true} type='password' value={secondPassword} onChange={(e) => { setSecondPassword(e.target.value) }} />
              </div>
            </section>
            <div className='terms'>
              <CheckBox className='check-terms' onValueChanged={(e) => { setTerms(e.value) }} />Estou de acordo com as <a href="https://uzeh.com.br/sitenovo/politica-de-privacidade/" target="_blank" rel="noopener noreferrer">Políticas de Privacidade.</a>
            </div>
            <div>
              {
                terms ?
                  <Button disabled={loading} type='default' width="100%" height='50px' useSubmitBehavior={true} style={{ padding: '0', marginTop: '20px', marginBottom: '10px' }} >
                    <ButtonOptions height='50px' style={{ backgroundColor: 'transparent', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                      <span className='dx-button-text' style={{ backgroundColor: 'transparent', textAlign: 'center', color: '#fff' }}>
                        {
                          loading ?
                            <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                            'CRIAR CADASTRO'
                        }
                      </span>
                    </ButtonOptions>
                  </Button>
                  :
                  <Button disabled={false} type='default' width="100%" height='50px' useSubmitBehavior={false} style={{ padding: '0', marginTop: '20px', marginBottom: '10px', opacity: '0.5' }} >
                    <ButtonOptions height='50px' style={{ backgroundColor: 'transparent', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                      <span className='dx-button-text' style={{ backgroundColor: 'transparent', textAlign: 'center', color: '#fff' }}>
                        {
                          loading ?
                            <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                            'CRIAR CADASTRO'
                        }
                      </span>
                    </ButtonOptions>
                  </Button>
              }
            </div>
            <div className={'login-link'}>
              Já tem cadastro? <Link to={'/login'}>Realize o Login</Link>
            </div>
          </form>
          <Popup
            visible={modal}
            closeOnOutsideClick={true}
            showCloseButton={true}
            showTitle={false}
            container=".dx-viewport"
            onHiding={onCloseModal}
            width={'45%'}
            height='59vh'
          >
            <div className='content-modal'>
              <header />
              <div className='div-button'>
                <button onClick={onCloseModal}>
                  <RiCloseLine />
                </button>
              </div>
              <img src={logo} alt="logo" />
              <div className='content-texts-modal-welcome'>
                <p className='title-1'>Bem-vindo!</p>
                <p>Juntos vamos ajudar a limpar o planeta.</p>
                <p className='title-bottom'>Realize seu cadastro na plataforma para darmos início.</p>
              </div>
            </div>
          </Popup>
          <Popup
            visible={modalSuccess}
            closeOnOutsideClick={true}
            showCloseButton={true}
            showTitle={false}
            container=".dx-viewport"
            onHiding={onCloseModalSuccess}
            width={'50%'}
            height='30vh'
          >
            <ContentModalSuccess>
              <header />
              <div className='content'>
                <FaRegCheckCircle color='#ff5722' className='icon-success' />
                <div>
                  <p>Cadastro realizado com sucesso!</p>
                  <span>Agora você pode cadastrar seu Ecoponto!</span>
                </div>
              </div>
              <button onClick={onLogin}>Continuar</button>
            </ContentModalSuccess>
          </Popup>
          <Toast
            visible={toast}
            type={type}
            onHiding={onCloseToast}
            contentComponent={() => RenderToastPassword(situation)}
            displayTime={4000}
            height='85px'
            animation={{
              hide: { type: 'slide', duration: 400, to: { position: { my: 'top', at: 'bottom', of: window } } },
              show: { type: 'slide', duration: 400, from: { position: { my: 'top', at: 'bottom', of: window } } }
            }}
          />
        </>
      }
    </Container>
  );
}

function RenderToastPassword(situation) {
  return (
    <>
      {
        situation === 'password' &&
        <>
          <IoMdCloseCircle color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
          <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Senhas não conferem</p>
        </>
      }
      {
        situation === 'email' &&
        <>
          <BsFillExclamationCircleFill color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
          <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>E-mail ou Nº de celular já cadastrados.</p>
        </>
      }
      {
        situation === 'fail' &&
        <>
          <IoMdCloseCircle color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
          <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Falha na criação do usuário.</p>
        </>
      }
    </>
  )
}
