import styled from "styled-components";

export const Container = styled.div`
    border-radius: 5px;

    /* @media(max-width: 796px) {
        width: 30%;
    } */
`;

export const Text = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
`;