import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes, { routeEco } from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';
import { BsInstagram, BsGlobe2, BsWhatsapp } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';

export default function Content() {

  const { user } = useAuth();

  return (
    <SideNavBarLayout title={appInfo.title} >
      <Switch>
        {user.user_type_id === 1 && routes.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        {user.user_type_id !== 1 && routeEco.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        <Redirect to={user.user_type_id === 1 ? '/home' : '/registerecopoint'} />
      </Switch>
      <Footer>
        {user.user_type_id !== 1 ?
          <div className='content-footer'>
            <div>
              Copyright © {new Date().getFullYear()} uZeh.
              <br />
              todos os direitos reservados
            </div>
            <div className='contact-footer'>
              <BsWhatsapp className='logo-whats' />
              <div>
                Contato
                <span>(84)99951-0085</span>
              </div>
            </div>
            <div>
              <a className='ancor-footer' href="https://uzeh.com.br/sitenovo/politica-de-privacidade/" target="_blank" rel="noopener noreferrer"><IoDocumentTextOutline className='logo-ancor' />Política de Privacidade</a>
            </div>
            <div>
              <a className='ancor-footer' href="https://uzeh.com.br/sitenovo/" target="_blank" rel="noopener noreferrer"><BsGlobe2 className='logo-ancor' />Site oficial</a>
            </div>
            <div >
              <a className='ancor-footer' href="https://uzeh.com.br/sitenovo/" target="_blank" rel="noopener noreferrer"><BsInstagram className='logo-ancor' />@chamauzeh</a>
            </div>
          </div>
          :
          <>
            Copyright © {new Date().getFullYear()} uZeh.
            <br />
            todos os direitos reservados
          </>
        }

      </Footer>
    </SideNavBarLayout>
  );
}
