import { useState } from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import api from '../services/api';

export default function useCardCollect(pageLimit) {
    
    const [collects, setCollects] = useState([])
    const [loadingCards, setLoadingCards] = useState(true)
    const [offset, setOffset] = useState(0);

    async function loadCollects(page) {
        
        const virtualPage = ((page - 1) * pageLimit)  <= 0 
        ? 1
        : ((page - 1) * pageLimit)

        // console.log('page:',page,'pageLimit:',pageLimit,'virtualPage:',virtualPage)

        setLoadingCards(true)
        
        await api.get(`/adm/collections?limit=${pageLimit}&page=${page}`).then(res => {
            const data = res.data.map(col => {
              return {
                id: col.id,
                name: col.user.fullname,
                photo: col.user.photo,
                date_collect: col.created_at,
                date: format(new Date(col.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
                type_collect: col.type,
                shift: col.shift,
                status: col.status,
                quantity: col.quantity,
                addressPeq: `${col.address.district}, ${col.address.city}, ${col.address.state}`,
                address: `${col.address.street}, ${col.address.number}, ${col.address.district}, ${col.address.city}, ${col.address.state}`,
                local: col.address.local,
                lat: col.address.lat,
                lng: col.address.long,
                note: col.note,
                categories: col.categories,
                photoCollect: col.images,
                discart: col?.discart?.status ? col.discart.status : "Ecoponto não selecionado"
              }
            })
            setCollects(data)
          })
        
        setLoadingCards(false)
        setOffset(virtualPage)
    }  

    return{
        loadCollects,
        collects,
        loadingCards,
        offset
    }
}
