import { Container } from './styles';
import InputMask from "react-input-mask";
import { CpfCpnjInput } from '../cpf-verification/styles';

export function InputForm({ htmlFor, label, name, required, type, value, onChange, id, autoFocus, disable, minLength, inputMode, maxLength, style, pattern, onBlur }) {
    return (
        <Container>
            <label htmlFor={htmlFor}>{label}</label>
            <input onBlur={onBlur} style={style} pattern={pattern} name={name} minLength={minLength} maxLength={maxLength} inputMode={inputMode} disabled={disable} id={id} required={required} autoFocus={autoFocus} type={type} value={value} onChange={onChange} />
        </Container>
    )
}

export function InputFormMask({ htmlFor, label, name, required, type, value, onChange, mask, autoFocus, disable }) {
    return (
        <Container>
            <label htmlFor={htmlFor}>{label}</label>
            <InputMask mask={mask} disabled={disable} name={name} autoFocus={autoFocus} required={required} type={type} value={value} onChange={onChange} />
        </Container>
    )
}

export function InputFormCpf({htmlFor, label, name, required, type, value, onChange, disable }) {
    return(
        <Container>
            <label htmlFor={htmlFor}>{label}</label>
            <CpfCpnjInput 
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                required={required}
                disable={disable}
            />
        </Container>
    )
}