

export const navigation = [
  {
    text: 'Início',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Listagens',
    icon: 'bulletlist',
    items: [
      {
        text: 'Usuários',
        path: '/users'
      },
      {
        text: 'Descartes',
        path: '/collects'
      }
    ]
  },
  {
    text: 'Ecopontos',
    icon: 'trash',
    items: [
      {
        text: 'Cadastrar',
        path: '/registerecopoint'
      },
      {
        text: 'Listar',
        path: '/listecopoints'
      }
    ]
  },
  {
    text: 'Parceiros',
    icon: 'favorites',
    items: [
      {
        text: 'Cadastrar',
        path: '/registerpartner'
      },
      {
        text: 'Listar',
        path: '/listpartner'
      }
    ]
  }
  ];

export const navigationEco = [
  {
    text: 'Ecoponto',
    icon: 'trash',
    items: [
      {
        text: 'Cadastrar',
        path: '/registerecopoint'
      },
      {
        text: 'Listar',
        path: '/listecopoints'
      }
    ]
  }
];
