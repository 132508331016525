import styled from "styled-components";

export const Container = styled.div`
    height: 80vh;
`;

export const ContentModalMap = styled.div`
    p, span {
        font-family: 'Jost', sans-serif;
    }

    p {
        margin-bottom: 0;
    }

    span {
        font-weight: bold;
    }

    .content-ecoponto {
        display: flex;
        flex-direction: row;

        .foto-ecoponto {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 300px;
                height: 300px;
                border-radius: 0.25rem;
            }
        }
    }

    .content-gerador {
        display: flex;
        flex-direction: row;

        .avatar-gerador {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 300px;
                height: 300px;
                border-radius: 0.25rem;
            }
        }
    }

    .content-coletor {
        display: flex;
        flex-direction: row;

        .avatar-coletor {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 300px;
                height: 300px;
                border-radius: 0.25rem;
            }
        }
    }

    .content-descarte {
        display: flex;
        flex-direction: row;

        .content-image-descarte {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 300px;
                height: 300px;
                border-radius: 0.25rem;
            }
        }
    }
`;