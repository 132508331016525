import styled from "styled-components";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

export const CpfCpnjInput = styled(CpfCnpj)`
    width: 100%;
    border: 0;
    border-radius: 3px;
    height: 3rem;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    outline: none;
    background-color: rgba(245,245,245,1);
    border-bottom: 1px solid rgba(0,0,0,0.45);
    transition: all .3s;
    font-size: 16px;

    :focus{
        border-bottom: 2.5px solid #ff5722;
    }

    :hover {
        background-color:rgba(0,0,0,.09);
    }
`;