import React, { useState, useEffect, useCallback, useRef } from "react";

import {
    DataGrid,
    Popup,
    ScrollView,
    CheckBox, Button,
    RadioGroup,
    FileUploader,
    LoadIndicator,
    SelectBox,
    SpeedDialAction,
} from "devextreme-react";

import {
    Column,
    Editing,
    Export,
    Item,
    Paging,
    SearchPanel,
    Selection,
    Toolbar
} from "devextreme-react/data-grid";

import notify from 'devextreme/ui/notify';
import ButtonOptions from 'devextreme-react/button';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';

import { useAuth } from "../../contexts/auth";
import { format } from 'date-fns';
import api from '../../services/api';
import { google } from "../../services/google";
import axios from "axios";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import noPhoto from '../../assets/nophoto.png';
import catMetal from '../../assets/metal-2.svg';
import catElet from '../../assets/eletronicos.svg'
import catPapel from '../../assets/papeis.svg';
import catVidro from '../../assets/vidros.svg';
import catPlastico from '../../assets/plastico.svg';
import catOutros from '../../assets/othes.svg';
import uzehwhite from '../../assets/uzehnewlogowhite.svg';

import {
    Container,
    Header,
    ContentModalEcopoint,
} from './styles';

import { HeaderText } from "../../components/header-text";
import { MapsGoogleAll } from "../../components/maps-google-all";
import { InputForm, InputFormMask } from "../../components/InputForm";
import { MapsGoogleRegisterEco } from "../../components/maps-google-register-eco";
import { LoadTrash } from "../../components/load-trash";


export function ListEcopoint() {

    const [ecopoints, setEcopoints] = useState([]);
    const [ecopointSelected, setEcopointSelected] = useState(['']);
    const [idEco, setIdEco] = useState(0);
    const [ecoSize, setEcoSize] = useState('');
    const [ecoActivation, setEcoActivation] = useState(0);
    const [ecoPhoto, setEcoPhoto] = useState([]);
    const [userEco, setUserEco] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingActive, setLoadingActive] = useState(false);
    const [modal, setModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [categories, setCategories] = useState([]);
    const [activationModal, setActivationModal] = useState(false);

    const [editNameEco, setEditNameEco] = useState('');
    const [editPhoneEco, setEditPhoneEco] = useState('');
    const [editZipCodeEco, setEditZipCodeEco] = useState('');
    const [editStreetEco, setEditStreetEco] = useState('');
    const [editStreetEcoFormat, setEditStreetEcoFormat] = useState('');
    const [editNumberEco, setEditNumberEco] = useState('');
    const [editDistrictEco, setEditDistrictEco] = useState('');
    const [editCityEco, setEditCityEco] = useState('');
    const [editStateEco, setEditStateEco] = useState('');
    const [editObsEco, setEditObsEco] = useState('');
    const [editTypeEco, setEditTypeEco] = useState('')
    const [editLatEco, setEditLatEco] = useState('');
    const [editLngEco, setEditLngEco] = useState('');
    const [editIpEco, setEditIpEco] = useState('');
    const [editLatAtualEco, setEditLatAtualEco] = useState('');
    const [editLngAtualEco, setEditLngAtualEco] = useState('');
    const [loadingEditing, setLoadingEditing] = useState(false);
    const [newPosition, setNewPosition] = useState({})

    const [metal, setMetal] = useState(false);
    const [papel, setPapel] = useState(false);
    const [vidro, setVidro] = useState(false);
    const [plastico, setPlastico] = useState(false);
    const [eletronico, setEletronico] = useState(false);
    const [outros, setOutros] = useState(false);

    const activeEco = new FormData();

    const { user } = useAuth();

    const dataGridRef = useRef(null);

    const exportGridPDF = useCallback(() => {
        const doc = new jsPDF();
        const dataGrid = dataGridRef.current.instance;

        exportDataGridToPdf({
            jsPDFDocument: doc,
            component: dataGrid,
            autoTableOptions: {
                theme: 'striped',
                headStyles: {
                    textColor: '#ffffff',
                    valign: 'middle',
                    fontStyle: 'bold'
                },
                bodyStyles: {
                    lineWidth: 0
                }
            }
        }).then(() => {
            doc.save('Ecopontos.pdf');
        })
    }, [])

    const onExportingExcel = useCallback((e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGridToExcel({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Ecopontos.xlsx');
            })
        })
        e.cancel = true;
    }, [])

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data);
        setEditIpEco(res.data.IPv4);
        setEditLatAtualEco(res.data.latitude);
        setEditLngAtualEco(res.data.longitude);
    }

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
    }, [])

    async function loadData() {
        setLoading(true);
        if (user.user_type_id === 1) {
            await api.get('/ecopoints').then(res => {
                const data = res.data.map(eco => {
                    return {
                        id: eco.id,
                        name: eco.name,
                        type: eco.type,
                        obs: eco.obs,
                        size: eco.size,
                        phone: eco.phone,
                        status: eco.status,
                        user: eco.user_id,
                        dateCreated: eco.created_at,
                        dateCreatedFormatted: format(new Date(eco.created_at), 'dd/MM/yyyy'),
                        photo: eco.photos,
                        lat: eco.address.lat,
                        lng: eco.address.long,
                        categories: eco.categories,
                        street: eco.address.street,
                        number: eco.address.number,
                        district: eco.address.district,
                        city: eco.address.city,
                        state: eco.address.state,
                        zipcode: eco.address.zipcode,
                        address_type: eco.address.type,
                        address_local: eco.address.local,
                        address_id: eco.address.id,
                        address: `${eco.address.street}, ${eco.address.number}, ${eco.address.district}, ${eco.address.city}, ${eco.address.state}`
                    }
                })
                setEcopoints(data)
            })
            await api.get('/adm/users').then(res => {
                const data = res.data.map(us => {
                    return {
                        name: us.name,
                        fullname: us.fullname,
                        id: us.id
                    }
                })
                setUserEco(data);
            })
            await api.get('/categories').then(res => {
                const data = res.data.map(cat => {
                    return {
                        id: cat.id,
                        description: cat.description
                    }
                })
                setCategories(data)
            })
            setLoading(false)
        } else {
            await api.get('/ecopoints').then(res => {
                const data = res.data.map(eco => {
                    return {
                        id: eco.id,
                        name: eco.name,
                        type: eco.type,
                        obs: eco.obs,
                        status: eco.status,
                        user: eco.user_id,
                        dateCreated: eco.created_at,
                        categories: eco.categories,
                        address: `${eco.address.street}, ${eco.address.number}, ${eco.address.district}, ${eco.address.city}, ${eco.address.state}`
                    }
                })
                setEcopoints(data)
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    function onSelectionChanged({ selectedRowsData }) {
        const data = selectedRowsData[0];
        setEcopointSelected(data)
        setEditing(false);
        setModal(true);
    }

    function onCloseModal() {
        setModal(false)
        setMetal(false)
        setPapel(false)
        setVidro(false)
        setPlastico(false)
        setOutros(false)
        setEletronico(false)
        setActivationModal(false)
    }

    function getNameUser(data) {
        const userEcopoint = userEco.map(u => {
            if (u.id === data.value) {
                return u.name;
            }
        })
        return <div>{userEcopoint}</div>
    }

    function getNameUserModal(data) {
        const userEcopoint = userEco.map(u => {
            if (u.id === data) {
                return u.fullname;
            }
        })
        return userEcopoint
    }

    function typeEco(data) {
        if (data.value === 'free') {
            return <span style={{
                backgroundColor: "#ff5722",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Gratuito</span>
        } else {
            return <span style={{
                backgroundColor: "#3EB595",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Premium</span>
        }
    }

    function statusEco(data) {
        if (data.value === 0) {
            return <div style={{ backgroundColor: "#7F7F7F", color: "white", borderRadius: "5px" }}>Inativo</div>
        } else {
            return <div style={{ backgroundColor: "#3EB595", color: "white", borderRadius: "5px" }}>Ativo</div>
        }
    }

    function statusEcoModal(data) {
        if (data === 0) {
            return <span style={{
                backgroundColor: "#7F7F7F",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Inativo</span>
        } else {
            return <span style={{
                backgroundColor: "#3EB595",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Ativo</span>
        }
    }

    function categoriesEco(data) {
        const catEco = data.value.map(ct => {
            return ct.description
        })
        return <div>{`${catEco}`}</div>
    }

    function phoneEco(data) {
        if (data) {
            return data
        } else {
            return 'Não informado'
        }
    }

    function categoriesEcoModal(data) {
        // console.log('data:',data)
        let catEco = '';
        data?.map(ct => {
            if (catEco === '') {
                catEco = `${ct.description}`
            } else {
                catEco = `${catEco}, ${ct.description}`
            }
        })
        return catEco
    }

    function obsModal(data) {
        if (data) {
            return data
        } else {
            return 'Não'
        }
    }

    function sizeEcopointRender(data) {
        if (data === 'small') {
            return 'Pequeno Porte'
        }
        if (data === 'medium') {
            return 'Médio Porte'
        }
        if (data === 'large') {
            return 'Grande Porte'
        }
        if (data === 'xlarge') {
            return 'Extra Grande'
        }
    }

    function planEcoRender(data) {
        if (data === 'free') {
            return <span style={{
                backgroundColor: "#ff5722",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Gratuito</span>
        } else {
            return <span style={{
                backgroundColor: "#3EB595",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Premium</span>
        }
    }

    const sizeEcoponto = [
        { text: 'P', value: 'small' },
        { text: 'M', value: 'medium' },
        { text: 'G', value: 'large' },
        { text: 'XG', value: 'xlarge' }
    ]

    useEffect(() => {
        ecopointSelected.categories?.map((ecoPoint) => {
            // console.log(ecoPoint)
            // console.log(ecoPoint.id)

            if (ecoPoint.id === 1) {
                setMetal(true);
            }
            if (ecoPoint.id === 2) {
                setPapel(true);
            }
            if (ecoPoint.id === 3) {
                setVidro(true);
            }
            if (ecoPoint.id === 4) {
                setPlastico(true);
            }
            if (ecoPoint.id === 5) {
                setOutros(true);
            }
            if (ecoPoint.id === 6) {
                setEletronico(true);
            }

        })
    }, [ecopointSelected])

    useEffect(() => {
        setEditNameEco(ecopointSelected.name)
        setEditPhoneEco(ecopointSelected.phone)
        setEditZipCodeEco(ecopointSelected.zipcode)
        setEditStreetEco(ecopointSelected.street)
        setEditNumberEco(ecopointSelected.number)
        setEditDistrictEco(ecopointSelected.district)
        setEditCityEco(ecopointSelected.city)
        setEditStateEco(ecopointSelected.state)
        setEditObsEco(ecopointSelected.obs)
        setEditTypeEco(ecopointSelected.type)
        setIdEco(ecopointSelected.id)
        setEcoActivation(ecopointSelected.status)
        setNewPosition({ latCenter: ecopointSelected.lat, lngCenter: ecopointSelected.lng, latMarker: ecopointSelected.lat, lngMarker: ecopointSelected.lng })
    }, [ecopointSelected])

    useEffect(() => {
        if (editStreetEco !== undefined) {
            setEditStreetEcoFormat(editStreetEco.split(" ").join("+"))
        }
    }, [editStreetEco])

    const loadLatLong = useCallback(async () => {
        await google.get(`/json?address=${editNumberEco}+${editStreetEcoFormat},+${editDistrictEco},+${editCityEco},+${editStateEco},+BR&key=${process.env.REACT_APP_GOOGLE_API}`)
            .then((res) => {
                setEditLatEco(res.data.results[0].geometry.location.lat)
                setEditLngEco(res.data.results[0].geometry.location.lng)
                // console.log("lat: ", res.data.results[0].geometry.location.lat, " e long: ", res.data.results[0].geometry.location.lng)
            })
    }, [
        editNumberEco,
        editStreetEcoFormat,
        editDistrictEco,
        editCityEco,
        editStateEco
    ])

    useEffect(() => {
        if (editNumberEco !== '' && editStreetEcoFormat !== '' && editDistrictEco !== '' && editCityEco !== '' && editStateEco !== '') {
            loadLatLong();
        }
    }, [
        editNumberEco,
        editStreetEcoFormat,
        editDistrictEco,
        editCityEco,
        editStateEco
    ])

    useEffect(() => {
        setEditLatEco(newPosition.latMarker);
        setEditLngEco(newPosition.lngMarker);
        // console.log('lat new position:', editLatEco, ', lng new position:', editLngEco)
    }, [newPosition])

    const handleActivationEco = useCallback(async (e) => {
        e.preventDefault();
        setLoadingActive(true);

        activeEco.append('eco_id', idEco);
        activeEco.append('active', ecoActivation);
        activeEco.append('size', ecoSize);
        activeEco.append('file', ecoPhoto);

        try {
            const response = await api.put('/ecopoints/active', activeEco)
            // console.log(response);
            notify("Processo efetuado com sucesso!", "success", 4000)
            onCloseModal()
            loadData()
            setLoadingActive(false);
        } catch (e) {
            // console.log(e)
            // console.log(e.response.data.message)
            notify("Falha no processo", "error", 4000)
            setLoadingActive(false);
        }

    }, [
        activeEco,
        idEco,
        ecoActivation,
        ecoSize,
        ecoPhoto
    ])

    function editingOn() {
        setEditing(true);
    }

    function editingOff() {
        setEditing(false)
    }

    const ecoType = ['free', 'premium']

    const handleEditingEco = useCallback(async (e) => {
        e.preventDefault()
        setLoadingEditing(true)

        let cat;
        let catSelect = []
        if (metal) {
            cat = categories.filter(c => { return c.description === 'Metal' })
            catSelect.push(cat[0].id)
        }
        if (papel) {
            cat = categories.filter(c => { return c.description === 'Papel' })
            catSelect.push(cat[0].id)
        }
        if (vidro) {
            cat = categories.filter(c => { return c.description === 'Vidro' })
            catSelect.push(cat[0].id)
        }
        if (plastico) {
            cat = categories.filter(c => { return c.description === 'Plástico' })
            catSelect.push(cat[0].id)
        }
        if (eletronico) {
            cat = categories.filter(c => { return c.description === 'Eletrônicos' })
            catSelect.push(cat[0].id)
        }
        if (outros) {
            cat = categories.filter(c => { return c.description === 'Outros' })
            catSelect.push(cat[0].id)
        }

        const data = {
            name: editNameEco,
            categories: catSelect,
            street: editStreetEco,
            number: editNumberEco,
            address_id: ecopointSelected.address_id,
            eco_id: ecopointSelected.id,
            phone: editPhoneEco?.replace(/\D/g, ""),
            ecotype: editTypeEco,
            size: ecopointSelected.size,
            ecostatus: ecopointSelected.status,
            district: editDistrictEco,
            city: editCityEco,
            state: editStateEco,
            zipcode: editZipCodeEco,
            lat: `${editLatEco}`,
            long: `${editLngEco}`,
            type: ecopointSelected.address_type,
            local: ecopointSelected.address_local,
            ip: editIpEco,
            lat_atual: `${editLatAtualEco}`,
            long_atual: `${editLngAtualEco}`,
            obs: editObsEco,
        }

        try {
            const response = await api.put('/ecopoints', data)
            // console.log(response)
            notify("Edição efetuada com sucesso!", "success", 4000)
            loadData()
            onCloseModal()
            setLoadingEditing(false);
        } catch (e) {
            notify("Falha no processo", "error", 4000)
            // console.log(e.response.data.message)
            setLoadingEditing(false)
        }
        // console.log(response)
    }, [
        editNameEco,
        editStreetEco,
        editNumberEco,
        ecopointSelected,
        editPhoneEco,
        editTypeEco,
        editDistrictEco,
        editCityEco,
        editStateEco,
        editZipCodeEco,
        editLatEco,
        editLngEco,
        editIpEco,
        editLatAtualEco,
        editLngAtualEco,
        editObsEco,
        metal,
        papel,
        vidro,
        plastico,
        eletronico,
        outros
    ])

    return (
        <Container>

            <Header>
                <HeaderText text='Listagem de Ecopontos' />
            </Header>

            {loading &&
                <LoadTrash />
            }

            {!loading &&
                <>
                    {user.user_type_id === 1
                        ?
                        <>
                            <DataGrid
                                ref={dataGridRef}
                                dataSource={ecopoints}
                                showColumnLines={false}
                                showBorders={true}
                                showColumnHeaders={true}
                                showRowLines={true}
                                className='grid-list-ecopoints'
                                onSelectionChanged={onSelectionChanged}
                                onExporting={onExportingExcel}
                                keyExpr='id'
                            >
                                <SearchPanel visible={true} width={250} />
                                <Editing useIcons={true} allowUpdating={false} mode="popup" />
                                <Selection mode="single" />
                                <Paging defaultPageSize={30} />
                                <Column caption="ID" dataField="id" dataType="number" alignment="center" width={70} />
                                <Column caption="NOME" dataField="name" dataType="string" alignment="center" width={200} />
                                <Column caption="USUÁRIO" dataField="user" alignment="center" cellRender={getNameUser} width={100} allowExporting={false} />
                                <Column caption="PLANO" dataField="type" dataType="string" alignment="center" cellRender={typeEco} width={100} allowExporting={false} />
                                <Column caption="CATEGORIA(S)" dataField="categories" alignment="center" cellRender={categoriesEco} width={250} allowExporting={false} />
                                <Column caption="STATUS" dataField="status" alignment="center" cellRender={statusEco} width={90} allowExporting={false} />
                                <Column caption="DATA CRIAÇÃO" dataField="dateCreated" alignment="center" dataType="date" width={120} />
                                {/* <Column caption="CONTATO" dataField='phone' alignment='center' width={120} /> */}
                                <Column caption="ENDEREÇO" dataField="address" alignment="center" />
                                <Export enabled={true} />
                                <Toolbar>
                                    <Item>
                                        <Button icon="exportpdf" onClick={exportGridPDF} />
                                    </Item>
                                    <Item name='exportButton' />
                                    <Item name='searchPanel' />
                                </Toolbar>
                            </DataGrid>
                            <Popup
                                visible={modal}
                                closeOnOutsideClick={true}
                                showCloseButton={true}
                                showTitle={true}
                                container=".dx-viewport"
                                onHiding={onCloseModal}
                                className="modal-ecopontos"
                                title={
                                    editing ?
                                        `Editar (Ecoponto ID: ${ecopointSelected.id})` :
                                        (activationModal ? `Ativação (Ecoponto ID: ${ecopointSelected.id})` :
                                            `Detalhes do Ecoponto - (ID: ${ecopointSelected.id})`)
                                }
                            >
                                <ScrollView>
                                    <ContentModalEcopoint>
                                        {
                                            editing ?
                                                <>
                                                    <div className="div-button-back-activate">
                                                        <Button text="voltar" stylingMode="text" icon="back" onClick={editingOff} className='button-back' />
                                                    </div>
                                                    <header className="header-activate">
                                                        <img src={uzehwhite} alt="uzeh logo" />
                                                    </header>
                                                    <form className="form-edit-eco" onSubmit={handleEditingEco}>
                                                        <section className='content-edit-eco'>
                                                            <section className='first-section-edit-eco'>
                                                                <div style={{ width: '66%', marginRight: '2rem' }} >
                                                                    <InputForm
                                                                        htmlFor='name-eco'
                                                                        label='Nome do Ecoponto'
                                                                        name='name-eco'
                                                                        required={false}
                                                                        type='text'
                                                                        value={editNameEco}
                                                                        onChange={(e) => { setEditNameEco(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '31.5%' }}>
                                                                    <InputFormMask
                                                                        htmlFor='phone-eco'
                                                                        label='Contato'
                                                                        mask='(99)99999-9999'
                                                                        name='phone-eco'
                                                                        required={false}
                                                                        type='text'
                                                                        value={editPhoneEco}
                                                                        onChange={(e) => { setEditPhoneEco(e.target.value) }}
                                                                    />
                                                                </div>
                                                            </section>
                                                            <div className='sections-edit-eco'>
                                                                <InputForm
                                                                    htmlFor='zipcode-eco'
                                                                    label='CEP'
                                                                    name='zipcode-eco'
                                                                    required={false}
                                                                    type='text'
                                                                    value={editZipCodeEco}
                                                                    onChange={(e) => { setEditZipCodeEco(e.target.value) }}
                                                                />
                                                                <InputForm
                                                                    htmlFor='street-eco'
                                                                    label='Rua'
                                                                    name='street-eco'
                                                                    required={false}
                                                                    type='text'
                                                                    value={editStreetEco}
                                                                    onChange={(e) => { setEditStreetEco(e.target.value) }}
                                                                />
                                                                <InputForm
                                                                    htmlFor='number-eco'
                                                                    label='Número'
                                                                    name='number-eco'
                                                                    required={false}
                                                                    type='text'
                                                                    value={editNumberEco}
                                                                    onChange={(e) => { setEditNumberEco(e.target.value) }}
                                                                />
                                                            </div>
                                                            <div className='sections-edit-eco'>
                                                                <InputForm
                                                                    htmlFor='district-eco'
                                                                    label='Bairro'
                                                                    name='district-eco'
                                                                    required={false}
                                                                    type='text'
                                                                    value={editDistrictEco}
                                                                    onChange={(e) => { setEditDistrictEco(e.target.value) }}
                                                                />
                                                                <InputForm
                                                                    htmlFor='city-eco'
                                                                    label='Cidade'
                                                                    name='city-eco'
                                                                    required={false}
                                                                    type='text'
                                                                    value={editCityEco}
                                                                    onChange={(e) => { setEditCityEco(e.target.value) }}
                                                                />
                                                                <InputForm
                                                                    htmlFor='state-eco'
                                                                    label='Estado'
                                                                    name='state-eco'
                                                                    required={false}
                                                                    type='text'
                                                                    value={editStateEco}
                                                                    onChange={(e) => { setEditStateEco(e.target.value) }}
                                                                />
                                                            </div>
                                                            <section className='last-section-edit-eco'>
                                                                <div style={{ width: '66%', marginRight: '2rem' }}>
                                                                    <InputForm
                                                                        htmlFor='obs-eco'
                                                                        label='Observações'
                                                                        name='obs-eco'
                                                                        required={false}
                                                                        type='text'
                                                                        value={editObsEco}
                                                                        onChange={(e) => { setEditObsEco(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '31.5%' }}>
                                                                    <p>Tipo do ecoponto</p>
                                                                    <SelectBox
                                                                        dataSource={ecoType}
                                                                        value={editTypeEco}
                                                                        onSelectionChanged={(e) => { setEditTypeEco(e.selectedItem) }}
                                                                    />
                                                                </div>
                                                            </section>
                                                            <p className="title-categories-edit">Categorias</p>
                                                            <div className='edit-cat-eco'>
                                                                <div className="cats">
                                                                    <p>Metal</p>
                                                                    <img src={catMetal} alt="Metal" />
                                                                    <CheckBox className="check-categories" value={metal} onValueChange={() => { setMetal(!metal) }} />
                                                                </div>
                                                                <div className="cats">
                                                                    <p>Papel</p>
                                                                    <img src={catPapel} alt="Papel" />
                                                                    <CheckBox className="check-categories" value={papel} onValueChange={() => { setPapel(!papel) }} />
                                                                </div>
                                                                <div className="cats">
                                                                    <p>Vidro</p>
                                                                    <img src={catVidro} alt="Vidro" />
                                                                    <CheckBox className="check-categories" value={vidro} onValueChange={() => { setVidro(!vidro) }} />
                                                                </div>
                                                                <div className="cats">
                                                                    <p>Plástico</p>
                                                                    <img src={catPlastico} alt="Plástico" />
                                                                    <CheckBox className="check-categories" value={plastico} onValueChange={() => { setPlastico(!plastico) }} />
                                                                </div>
                                                                <div className="cats">
                                                                    <p>Eletrônicos</p>
                                                                    <img src={catElet} alt="Eletrônicos" />
                                                                    <CheckBox className="check-categories" value={eletronico} onValueChange={() => { setEletronico(!eletronico) }} />
                                                                </div>
                                                                <div className="cats">
                                                                    <p>Outros</p>
                                                                    <img src={catOutros} alt="Outros" />
                                                                    <CheckBox className="check-categories" value={outros} onValueChange={() => { setOutros(!outros) }} />
                                                                </div>
                                                            </div>
                                                            <section className="button-submit-edit-eco">
                                                                {/* <Button text="salvar alterações" type="success" useSubmitBehavior={true} /> */}
                                                                <Button className='button-submit' disabled={loadingEditing} type='success' useSubmitBehavior={true} style={{ padding: '0' }} >
                                                                    <ButtonOptions width={200} style={{ backgroundColor: 'transparent', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                                                                        <span className='dx-button-text' style={{ backgroundColor: 'transparent', textAlign: 'center', color: '#fff' }}>
                                                                            {
                                                                                loadingEditing ?
                                                                                    <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                                                                                    'salvar alterações'
                                                                            }
                                                                        </span>
                                                                    </ButtonOptions>
                                                                </Button>
                                                            </section>
                                                        </section>
                                                        <aside>
                                                            <MapsGoogleRegisterEco position={newPosition} height='60vh' setPosition={setNewPosition} zoom={16} />
                                                        </aside>
                                                    </form>
                                                </>
                                                :
                                                <>
                                                    {
                                                        activationModal ?
                                                            <>
                                                                <div className="div-button-back-activate">
                                                                    <Button text="voltar" stylingMode="text" icon="back" onClick={() => { setActivationModal(false) }} className='button-back' />
                                                                </div>
                                                                <header className="header-activate">
                                                                    <img src={uzehwhite} alt="uzeh logo" />
                                                                </header>
                                                                <form encType="multipart/form-data" method="post" onSubmit={handleActivationEco} className='content-modal-activation'>
                                                                    <div className="first-line-activation-eco">
                                                                        <InputForm
                                                                            htmlFor='ideco'
                                                                            label='ID do Ecoponto'
                                                                            name='ideco'
                                                                            value={idEco}
                                                                            type='number'
                                                                            readyOnly={true}
                                                                        />
                                                                        <div className="radio-group-activation">
                                                                            <p>Tamanho do Ecoponto</p>
                                                                            <div className="radio-group-div">
                                                                                <RadioGroup
                                                                                    elementAttr={{ style: { background: 'gray' } }}
                                                                                    style={{ background: 'gray' }}
                                                                                    className='radio-group'
                                                                                    layout={"horizontal"}
                                                                                    dataSource={sizeEcoponto}
                                                                                    valueExpr='value'
                                                                                    displayExpr='text'
                                                                                    onValueChanged={(e) => { setEcoSize(e.value) }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="first-line-activation-eco">
                                                                        <div>
                                                                            <p>Ativação do Ecoponto</p>
                                                                            <div className="div-activation-eco">
                                                                                <Button
                                                                                    className="button-activation"
                                                                                    text={ecoActivation === 1 ? 'ativado' : 'ativar'}
                                                                                    type={ecoActivation === 1 ? 'success' : 'normal'}
                                                                                    onClick={() => { setEcoActivation(1) }}
                                                                                />
                                                                                <Button
                                                                                    className="button-activation"
                                                                                    text={ecoActivation === 0 ? 'desativado' : 'desativar'}
                                                                                    type={ecoActivation === 0 ? 'danger' : 'normal'}
                                                                                    onClick={() => { setEcoActivation(0) }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p>Foto do Ecoponto</p>
                                                                            <FileUploader
                                                                                className='uploader-ecopoint'
                                                                                accept='image/*'
                                                                                multiple={false}
                                                                                allowCanceling={true}
                                                                                hint='Selecione uma imagem'
                                                                                hoverStateEnabled={true}
                                                                                invalidFileExtensionMessage='Arquivo não permitido.'
                                                                                uploadMode='useForm'
                                                                                onValueChanged={(e) => { setEcoPhoto(e.value[0]) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='button-active-eco'>
                                                                        <Button className='button-submit' disabled={loadingActive} type='success' useSubmitBehavior={true} style={{ padding: '0', marginTop: '1.5rem' }} >
                                                                            <ButtonOptions width={120} style={{ backgroundColor: 'transparent', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                                                                                <span className='dx-button-text' style={{ backgroundColor: 'transparent', textAlign: 'center', color: '#fff' }}>
                                                                                    {
                                                                                        loadingActive ?
                                                                                            <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                                                                                            'FINALIZAR'
                                                                                    }
                                                                                </span>
                                                                            </ButtonOptions>
                                                                        </Button>
                                                                    </div>
                                                                </form>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="header-modal-ecopoint-details">
                                                                    <div style={{ width: '50%' }}>
                                                                        <p>NOME DO ECOPONTO: <span>{ecopointSelected.name}</span></p>
                                                                        <p>USUÁRIO: <span>{getNameUserModal(ecopointSelected.user)}</span></p>
                                                                        <p>PLANO: <span>{planEcoRender(ecopointSelected.type)}</span></p>
                                                                        <p>CATEGORIAS: <span>{categoriesEcoModal(ecopointSelected?.categories)}</span></p>
                                                                        <p>TAMANHO: <span>{sizeEcopointRender(ecopointSelected.size)}</span></p>
                                                                        <p>STATUS: <span>{statusEcoModal(ecopointSelected.status)}</span></p>
                                                                        <p>DATA DE CRIAÇÃO: <span>{ecopointSelected.dateCreatedFormatted}</span></p>
                                                                        <p>OBSERVAÇÕES: <span>{obsModal(ecopointSelected.obs)}</span></p>
                                                                        <p>CONTATO: <span>{phoneEco(ecopointSelected.phone)}</span></p>
                                                                        <p>ENDEREÇO: <span>{ecopointSelected.address}</span></p>
                                                                    </div>
                                                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                                                        <p style={{ margin: '0' }}>Foto do Ecoponto:</p>
                                                                        {
                                                                            ecopointSelected.photo ?
                                                                                <img src={ecopointSelected.photo} alt="ecoponto" /> :
                                                                                <>
                                                                                    <img src={noPhoto} alt="no-photo" style={{ margin: '0' }} />
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="buttons-modal">
                                                                    <Button text="EDITAR ECOPONTO" type="default" onClick={editingOn} />
                                                                    <Button text="ATIVAÇÃO" type="success" onClick={() => { setActivationModal(true) }} style={{ marginLeft: '1rem' }} />
                                                                </div>
                                                                <div style={{ margin: "20px 20px 0 0" }}>
                                                                    <p style={{ margin: '0' }}>Mapa de localização do ecoponto:</p>
                                                                    <MapsGoogleAll position={ecopointSelected} />
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </ContentModalEcopoint>
                                </ScrollView>
                            </Popup>
                        </>
                        :
                        <>
                            <DataGrid
                                dataSource={ecopoints}
                                showColumnLines={false}
                                showBorders={true}
                                showColumnHeaders={true}
                                showRowLines={true}
                            >
                                <SearchPanel visible={true} />
                                <Column caption="ID" dataField="id" dataType="number" alignment="center" width={70} />
                                <Column caption="NOME" dataField="name" dataType="string" alignment="center" width={200} />
                                <Column caption="PLANO" dataField="type" dataType="string" alignment="center" cellRender={typeEco} width={120} />
                                <Column caption="CATEGORIA(S)" dataField="categories" alignment="center" cellRender={categoriesEco} width={300} />
                                <Column caption="STATUS" dataField="status" alignment="center" cellRender={statusEco} width={90} />
                                <Column caption="DATA CRIAÇÃO" dataField="dateCreated" alignment="center" dataType="date" width={150} />
                                <Column caption="ENDEREÇO" dataField="address" alignment="center" />
                            </DataGrid>

                            <SpeedDialAction
                                icon='tel'
                                label='whatsapp'
                                hint="Clique para iniciar uma conversa via WhatsApp."
                                onClick={() => {window.open('https://wa.me/5584999510085', '_blank')}}
                            />
                        </>
                    }
                </>
            }
            
        </Container>
    );
}