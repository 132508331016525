import styled from "styled-components";
import { DataGrid } from "devextreme-react";

export const Container = styled.div`
    padding: 0 30px; 

    .card-collect {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0.5rem;

        @media(max-width: 1300px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media(max-width: 1171px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media(max-width: 901px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 660px) {
            grid-template-columns: 1fr;
        }
    } 

    .content-buttons-card-collects {
        display: flex;
        justify-content: flex-end;

        @media(max-width: 560px) {
            flex-wrap: wrap;
        }
    }
    
    .button-paging-cards-collects {
        margin-top: 15px;
        margin-right: 5px;
        width: 10px;
        padding: 0px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .buttons-header-collect {
        margin-left: 0.5rem;
    }
`;

export const TableCollects = styled(DataGrid).attrs({
    keyExpr:"id",
    showColumnLines: false,
})`
    padding: 0;
    color: #1C4738;
    font-family: 'Jost', sans-serif;
    
    .dx-datagrid .dx-row > td{
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
    .dx-datagrid-headers .dx-row > td{
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: row;
    p{
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738; 
    }
`;

export const RowModal = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: -1.5rem;
    width: 100%;
    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;
        
        span {
            font-family: 'Jost', sans-serif;
            font-weight: 400;
            color: #1C4738;

        }
    }
`

