import { DataGrid } from "devextreme-react";
import styled from "styled-components";

export const Container = styled.div`
    padding: 0 30px;
`;

export const PhotoPartner = styled.img`
    width: 80px;
    height: 60px;
    margin: 0;
`;

export const GridPartner = styled(DataGrid)`
    padding: 0;
    font-family: 'Jost', sans-serif;
    color: #1C4738;
    
    .dx-datagrid .dx-row > td{
        padding-top: 0;
        padding-bottom: 0;
    }
    .dx-datagrid-headers .dx-row > td{
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;