import React from 'react';

import { 
    BsFillPersonCheckFill, 
    BsFillPersonXFill,
    BsTruckFlatbed,
    BsHandThumbsUpFill,
    BsTrash
} from 'react-icons/bs'

import { 
    Container, 
    Title, 
    Info,
    Underline,
    Header, 
    Icon, 
    ContentInfo, 
    Content, 
    Info1, 
    Info2,
} from './styles';

export function CardInfo({
    title, 
    info = 0, 
    type = 1,
    info1,
    info2,
    info3,
    info4, 
    onClick,
    ...rest
}) {
    
    function icon(data){
        if(data===1) {
            return <BsFillPersonCheckFill />
        }
        if(data===2) {
            return <BsFillPersonXFill/>
        }
        if(data===3) {
            return <BsTruckFlatbed/>
        }
        if(data===4){
            return <BsHandThumbsUpFill/>
        }
        if(data===5){
            return <BsTrash/>
        }
    }

    function colors(data) {
        if(data===1) {
            return "#1C47"
        }
        if(data===2) {
            return "red"
        }
        if(data===3) {
            return "#BD6021"
        }
        if(data===4){
            return "#BD6"
        }
        if(data===5){
            return "#BD6021"
        }
    }
    
    return (
        <Container className={'dx-card'} {...rest} onClick={onClick}>
            <Header>
                <Title>{title}</Title>
                <Icon style={{
                    color: colors(type)
                }} >{icon(type)}</Icon>
            </Header>
            <Info>{info}</Info>
            <Underline />
            <ContentInfo>
                <Content>
                    <Info1>{info1}</Info1>
                    <Info2>{info2}</Info2>
                </Content>
                <Content>
                    <Info1>{info3}</Info1>
                    <Info2>{info4}</Info2>
                </Content>
            </ContentInfo>
        </Container>
    );
}