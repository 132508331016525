import { useState } from "react";

import { 
    Container, 
    Content, 
    Underline, 
    ContentModal, 
    RowModal 
} from "./styles";

import { Button, Gallery, Popup, ScrollView } from "devextreme-react";

import { ActiveStatusCard } from "../ActiveStatusCard";
import { ActiveStatus } from "../ActiveStatus";
import { AiOutlineEnvironment } from "react-icons/ai";
import { MapsGoogleAll } from "../maps-google-all";

export function CardCollect({ photo }) {

    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({});

    function onHandleDetails(photo) {
        setModalData(photo);
        setModal(true);
    }

    function onCloseModal() {
        setModal(false)
    }

    function discartModal(data) {
        if (data === 'finalizada') {
          return <ActiveStatus text="check-in realizado" color="#3EB595" colorText="white" />
        }
        if (data === 'pendente') {
          return <ActiveStatus text="check-in pendente" color="#ED7A4D" colorText="black" />
        }
        if (data === 'Ecoponto não selecionado') {
          return <ActiveStatus text="Ecoponto não selecionado" color="#506AD4" colorText="white" />
        }
      }

    return (
        <Container className="dx-card" key={photo.id} >
            <Gallery
                noDataText="Coleta sem fotos"
                height={"30vh"}
                width={'100%'}
                initialItemWidth={180}
                stretchImages={false}
                showNavButtons={true}
                dataSource={photo.photoCollect && photo.photoCollect.map((pho) => { return pho.url })}
            />
            <Content>
                <p className="content-card-collect">NOME DO GERADOR</p>
                <p>{photo.name}</p>
                <p className="date-content-card-collect" >{photo.date}</p>
                <div className="infos-card-collect">
                    <p>Período: <span>{photo.shift}</span></p>
                    <p>Tipo: <span>{photo.type_collect}</span></p>
                </div>
                <div style={{ marginTop: '-0.8rem' }} className="infos-card-collect">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <p>Status: </p>
                        <div className="status-card-collect">
                            {photo.discart === 'Ecoponto não selecionado' &&
                                <ActiveStatusCard text="Ecoponto não selecionado" color="#506AD4" colorText="white" /> ||
                                photo.discart === 'pendente' &&
                                <ActiveStatusCard text="check-in pendente" color="#ED7A4D" colorText="black" /> ||
                                photo.discart === 'finalizada' &&
                                <ActiveStatusCard text="check-in realizado" color="#3EB595" colorText="white" />}
                        </div>
                    </div>
                    <p>Sacos: <span>{photo.quantity}</span></p>
                </div>
                <Underline />
                <div className="bottom-card-collect">
                    <AiOutlineEnvironment className="icon-bottom-card-collect" />
                    <p>{photo.addressPeq}</p>
                </div>
                <Button width={'100%'} text="DETALHES" type="normal" stylingMode="outlined" onClick={() => onHandleDetails(photo)} />
            </Content>
            <Popup
                visible={modal}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                container=".dx-viewport"
                onHiding={onCloseModal}
                className="modal-collects"
                title={`Detalhes do descarte (ID: ${modalData.id})`}
            >
                <ScrollView>
                    <ContentModal style={{ paddingRight: "0.5rem" }}>
                        <div style={{ width: '50%' }}>
                            <RowModal>
                                <p>{`NOME GERADOR: `}<span>{` ${modalData.name}`}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`DATA DA COLETA: `}<span>{` ${modalData.date}`}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`TIPO: `}<span>{` ${modalData.type_collect}`}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`PERÍODO: `}<span>{` ${modalData.shift}`}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`STATUS: `}<span>{discartModal(modalData.discart)}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`SACOS: `}<span>{` ${modalData.quantity}`}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`ENDEREÇO: `}<span>{` ${modalData.address}`}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`COMENTÁRIO: `}<span>{modalData.note === null ? "Não" : modalData.note}</span></p>
                            </RowModal>
                            <RowModal>
                                <p>{`CATEGORIAS: `}<span>{modalData.categories && modalData.categories.map((cat, index) => { return index === 0 ? cat.description : `, ${cat.description}` })}</span></p>
                            </RowModal>
                        </div>
                        <div style={{ width: "50%" }}>
                            <p>Localização da coleta:</p>
                            <MapsGoogleAll position={modalData} />
                            <p>Fotos da coleta:</p>
                            <Gallery
                                style={{ marginTop: "1rem" }}
                                noDataText="Coleta sem fotos"
                                height={500}
                                width={'100%'}
                                stretchImages={false}
                                initialItemWidth={380}
                                showNavButtons={true}
                                dataSource={modalData.photoCollect && modalData.photoCollect.map((pho) => { return pho.url })}
                            />
                        </div>
                    </ContentModal>
                </ScrollView>
            </Popup>
        </Container>
    );
}