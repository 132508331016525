import {
    Container,
    Text
} from './styles'

export function ActiveStatus ({text, color, colorText}) {
    return(
        <Container style={{ backgroundColor: color }}>
            <Text style={{ color: colorText }}>
                {text}
            </Text>
        </Container>
    );
}