import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import api from '../services/api';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
        api.defaults.headers.authorization = `Bearer ${result.data.token}`;
      }
      setLoading(false);
    })();
  }, []);

  const updateUser = useCallback((user) => {
    localStorage.setItem('@Uzeh:user', JSON.stringify(user))
    console.log(user)
  },[]);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data.user);
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Uzeh:token');
    localStorage.removeItem('@Uzeh:user');
    setUser(null);
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setUser, updateUser }} {...props} />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
