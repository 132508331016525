import styled from "styled-components";

export const Container = styled.div`
    p {
        font-family: 'Jost', sans-serif;
        text-align: center;
        font-size: 1.1rem;
        font-weight: 500;
        color: #1C4738;
    }
    
    .div-cpf-phone {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: -10px;
    }

    .div-password {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
`;

export const ContentModalSuccess = styled.div`
    height: 30vh;
    width: 125%;
    background-color: #1C4738;
    margin: -24px 0px 0px -24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    header {
        position: absolute;
        width: 100%;
        background: #ff5722;
        height:10px;
        top: 0;
    }

    .icon-success {
        font-size: 150px;
        margin-right: 15px;
        @media(max-width: 1190px) {
            font-size: 60px;
            margin-left: 30px;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 15rem 0 5rem;
        
        @media(max-width: 1480px) {
            padding: 0 9rem 0 1rem;
        }
        @media(max-width: 1190px) {
            flex-direction: column;
            padding: 0 4rem 0 0;
            text-align: center;
            margin-top: -3rem;
        }
        @media(max-width: 840px) {
            flex-direction: column;
            padding: 0 3rem 0 0;
            text-align: center;
            margin-top: -3rem;
        }
        @media(max-width: 750px) {
            flex-direction: column;
            padding: 0 2.5rem 0 0;
            text-align: center;
            margin-top: -3rem;
        }
        @media(max-width: 640px) {
            flex-direction: column;
            padding: 0 2rem 0 0;
            text-align: center;
            margin-top: -3rem;
        }
        @media(max-width: 580px) {
            flex-direction: column;
            padding: 0 1rem 0 0;
            text-align: center;
            margin-top: -3rem;
        }
    }

    span {
        font-family: 'Jost', sans-serif;
        color: #FFFFFF;
        margin-left: 1rem;
        font-size: 1.2rem;
        @media(max-width: 1480px) {
            font-size: 0.9rem;
        }
    }

    p {
        font-family: 'Jost', sans-serif;
        color: #FFFFFF;
        font-size: 2.2rem;
        margin: 0 0 0 1rem;
        flex-wrap: wrap;
        @media(max-width: 1718px) {
            font-size:2rem;
        }
        @media(max-width: 1480px) {
            font-size: 1.5rem;
        }
    }
    
    button {
        position: absolute;
        margin: auto;
        bottom: 1.5rem;
        left: 0;
        right: 0;
        width: 8rem;
        font-family: 'Jost', sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        background-image: linear-gradient(to right,#ff5722,#BD6021);
        border: none;
        border-radius: 0.1rem;
        color: #FFFFFF;
        height: 2.5rem;
    }

    button:hover {
        cursor: pointer;
    }
`;