import React, { useState, useCallback } from 'react';
import notify from 'devextreme/ui/notify';
import { useHistory } from 'react-router-dom';
import { Button, LoadIndicator } from 'devextreme-react';
import ButtonOptions from 'devextreme-react/button';
import api from '../../services/api';
import { CpfCpnjInput } from './styles';
import { InputFormCpf } from '../InputForm';

export function CpfVerification() {

    const history = useHistory();
    const [infoValue, setInfoValue] = useState('');
    const [mask, setMask] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await api.post('/users/verify', { search: infoValue })
            notify("Cadastro encontrado, realize o login", "success", 4000)
            setLoading(false)
            history.push('/login')
        } catch (e) {
            notify("Realize o cadastro para continuar", "error", 4000)
            history.push('/create-account')
            setLoading(false)
        }

    }, [infoValue, history]);

    return (
        <>
            <form className={'cpf-form'} onSubmit={onSubmit}>
                <InputFormCpf htmlFor='cpf' name='cpf' label='Informe seu CPF ou CNPJ para continuar' required={true} type='text' value={infoValue} onChange={(e) => {setInfoValue(e.target.value.replace(/\D/g, ""))}} />
                <Button disabled={loading} type='default' width="100%" useSubmitBehavior={true} style={{ padding: '0' }} >
                    <ButtonOptions style={{ backgroundColor: 'transparent', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                        <span className='dx-button-text' style={{ backgroundColor: 'transparent', textAlign: 'center', color: '#fff' }}>
                            {
                                loading ?
                                    <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                                    'Verificar'
                            }
                        </span>
                    </ButtonOptions>
                </Button>
            </form>
        </>

    );
}

