import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { Container } from "./styles";

export function MapsGoogleAll({ position }) {

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API
    })

    // console.log(position);

    return (
        <Container className="dx-card" style={{ height: "40vh" }}>
            {
                isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%', }}
                        center={{
                            lat: Number(position?.lat),
                            lng: Number(position?.lng)
                        }}
                        zoom={14}
                    >
                        <Marker
                            position={{
                                lat: Number(position?.lat),
                                lng: Number(position?.lng)
                            }}
                            key={position?.id + position?.lat + position?.lng}
                        />
                    </GoogleMap>
                ) : (
                    <></>
                )

            }
        </Container>
    );
}