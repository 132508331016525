import { withNavigationWatcher } from './contexts/navigation';
import { ProfilePage } from './pages/profile';
import { Collects } from './pages/collects';
import { Users } from './pages/users';
import { Home } from './pages/home/home';
import { RegisterEcopoint } from './pages/register-ecopoint';
import { ListEcopoint } from './pages/list-ecopoint';
import { RegisterPartner } from './pages/register-partiner';
import { ListPartner } from './pages/list-partner';

const routes = [
  {
    path: '/users',
    component: Users
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/collects',
    component: Collects
  },
  {
    path: '/registerecopoint',
    component: RegisterEcopoint
  },
  {
    path: '/listecopoints',
    component: ListEcopoint
  },
  {
    path: '/registerpartner',
    component: RegisterPartner
  },
  {
    path: '/listpartner',
    component: ListPartner
  }
];

const routesEco = [
  {
    path: '/registerecopoint',
    component: RegisterEcopoint
  },
  {
    path: '/listecopoints',
    component: ListEcopoint
  },
  {
    path: '/profile',
    component: ProfilePage
  }
];

export default routes.map(route => {
  
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});

export const routeEco = routesEco.map(rout => {
  return {
    ...rout,
    component: withNavigationWatcher(rout.component)
  };
});
