import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, FileUploader, SelectBox, LoadIndicator, LoadPanel } from 'devextreme-react';
import  ButtonOptions  from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';

import api from '../../services/api';

import { 
    Container, 
    ContentForm 
} from './styles';

import { HeaderText } from '../../components/header-text';
import { InputForm } from '../../components/InputForm';

export function RegisterPartner() {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState();
    const [photo, setPhoto] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const imgPartner = new FormData();

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        // setLoading(true);
        await api.get('/adm/users?address=1').then(res => {
            const data = res.data.map(us => {
                return {
                    id: us.id,
                    name: us.fullname
                }
            })
            setUsers(data)
        })
        // setLoading(false);
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);

        imgPartner.append('file', photo);
        imgPartner.append('description', description);
        imgPartner.append('user_id', userSelected);
        imgPartner.append('name', name);

        const response = await api.post('/partners', imgPartner)

        if (!response) {
            notify("Falha ao cadastrar parceiro. Tente novamente", "error", 4000)
            setLoading(false)
        } else {
            notify("Parceiro cadastrado com sucesso", "success", 4000)
            history.push('/listpartner')
        }
        // console.log(response)
    }, [photo, description, name, userSelected, users, imgPartner])

    return (
        <Container>
            <HeaderText text='Cadastrar Parceiro' />
            <ContentForm encType="multipart/form-data" method='post' className='dx-card' onSubmit={handleSubmit}>
                <div className='content-inputs'>
                    <InputForm
                        htmlFor='name'
                        label='Nome'
                        name='name'
                        required={true}
                        type='text'
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                    />
                    <div>
                        <p>Usuário</p>
                        <SelectBox
                            className='select-users-partner'
                            dataSource={users}
                            displayExpr='name'
                            valueExpr='id'
                            showClearButton={true}
                            searchEnabled={true}
                            placeholder="Pesquise aqui..."
                            noDataText="Carregando dados..."
                            onValueChanged={(e) => { setUserSelected(e.value) }}
                        />
                    </div>
                </div>
                <InputForm
                    htmlFor='description'
                    label='Descrição'
                    name='description'
                    required={true}
                    type='text'
                    value={description}
                    onChange={(e) => { setDescription(e.target.value) }}
                />
                <div className='bottom-register-partner'>
                    <FileUploader
                        className='uploader-partner'
                        accept='image/*'
                        multiple={false}
                        allowCanceling={true}
                        hint='Selecione uma ou mais imagens'
                        hoverStateEnable={true}
                        invalidFileExtensionMessage='Arquivo não permitido.'
                        onValueChanged={(e) => { setPhoto(e.value[0]) }}
                        uploadMode='useForm'
                    />
                    <div className='button-submit-partner'>
                        <Button className='button-submit' disabled={loading} type='success' useSubmitBehavior={true} style={{padding:'0'}} >
                            <ButtonOptions width={120} style={{backgroundColor: 'transparent', width:'100%',display:'flex' , justifyContent:'center', marginTop:'-5px'}}>
                                <span className='dx-button-text' style={{backgroundColor: 'transparent', textAlign:'center',color:'#fff'}}>
                                       {
                                        loading ?
                                        <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                                        'Cadastrar'
                                    }
                                </span>
                            </ButtonOptions>
                        </Button>
                    </div>
                </div>
                {
                    loading &&
                    <LoadPanel visible={true} />
                }
            </ContentForm>
        </Container>
    );
}