import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card-cpf.scss';
import logo from '../../assets/uzehnewlogo.svg';

export default function SingleCardCpf({ title, description, children }) {
  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card-cpf'}>
      <div className={'dx-card content'}>
        <div className={'header'}>
        <img src={logo} className="logouzeh" alt="logo" width='120px'></img>
          {/* <div className={'title'}>{title}</div> */}
          {/* <div className={'description'}>{description}</div> */}
        </div>
        {children}
      </div>
    </ScrollView>
)}
