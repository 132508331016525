import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, {useRef} from "react";
import { Container } from "./styles";

export function MapsGoogleRegisterEco({ position, setPosition, height, zoom }) {
    const markRef = useRef({Marker});
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API
    })
    // console.log(position);

    return (
        <Container style={{ height: height }}>
            {
                isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%', }}
                        center={{
                            lat: Number(position.latCenter),
                            lng: Number(position.lngCenter)
                        }}
                        zoom={zoom}
                    >
                        <Marker
                            ref={markRef}
                            position={{
                                lat: Number(position.latMarker),
                                lng: Number(position.lngMarker)
                            }}
                            key={position.lat + position.lng}
                            draggable={true}
                            onDragEnd={(a) => {
                                const {latLng} = a;
                                // console.log(latLng.lat(), latLng.lng())
                                setPosition({latMarker: latLng.lat(), lngMarker: latLng.lng()})
                            }}
                            // onDragStart={(e) => {console.log('start: ',e)}}
                        />
                    </GoogleMap>
                ) : (
                    <></>
                )

            }
        </Container>
    );
}