import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { Container, ContentModalMap } from "./styles";
import markCollect from '../../assets/markCollect.png'
import markGenerator from '../../assets/markGenerator.png';
import markEcoPoint from '../../assets/markEcopoint.png';
import { Gallery, Popup } from "devextreme-react";
import img from '../../assets/defaultuser.png';
import imgEcoDefault from '../../assets/placeimage.png';
import { v4 as uuid } from 'uuid';

export function MapsGoogle({ position }) {

    const [modal, setModal] = useState(false);
    const [info, setInfo] = useState([]);
    const [lat, setLat] = useState(-5.8854215);
    const [lng, setLng] = useState(-35.2508259);
    const [ref, setRef] = useState();
    

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API
    })

    function onCloseModal() {
        setModal(false)
    }

    function sizeEcoponto(data) {
        if (data === 'small') {
            return 'Pequeno Porte'
        }
        if (data === 'medium') {
            return 'Médio Porte'
        }
        if (data === 'large') {
            return 'Grande Porte'
        }
        if (data === 'xlarge') {
            return 'Extra Grande'
        }
    }

    function statusEcoModal(data) {
        if (data === 0) {
            return <span style={{
                backgroundColor: "#7F7F7F",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Inativo</span>
        } else {
            return <span style={{
                backgroundColor: "#3EB595",
                color: "white",
                borderRadius: "5px",
                padding: "0 10px",
                textAlign: "center"
            }}>Ativo</span>
        }
    }

    return (
        <Container>
            {
                isLoaded ? (
                    <>
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%', }}
                            center={{
                                lat: lat,
                                lng: lng
                            }}
                            zoom={12}
                            ref={(e) => {setRef(e)}}
                            onDragEnd={() => {
                                const latCut = `${ref.state.map.center.lat()}`.split('.');
                                const latRedux = latCut[1].slice(0,7)
                                const latFinal = latCut[0].concat('.').concat(latRedux)
                                
                                const lngCut = `${ref.state.map.center.lng()}`.split('.');
                                const lngRedux = lngCut[1].slice(0,7)
                                const lngFinal = lngCut[0].concat('.').concat(lngRedux)

                                setLat(Number(latFinal));
                                setLng(Number(lngFinal));
                            }}
                        >
                            {position.map((mark) => (
                                <Marker
                                    position={{
                                        lat: Number(mark.lat),
                                        lng: Number(mark.lng)
                                    }}
                                    key={uuid()}
                                    icon={
                                        (mark.typeColor === "ecoponto" && markEcoPoint) ||
                                        (mark.typeColor === "gerador" && markGenerator) ||
                                        // (mark.typeColor === "coletor" && markCollector) ||
                                        (mark.typeColor === "coleta" && markCollect)
                                    }
                                    onClick={() => {
                                        setModal(true)
                                        setInfo(mark)
                                    }}
                                />
                            ))}
                        </GoogleMap>
                        <Popup
                            visible={modal}
                            closeOnOutsideClick={true}
                            showCloseButton={true}
                            showTitle={true}
                            container=".dx-viewport"
                            onHiding={onCloseModal}
                            width={700}
                            height={
                                info.typeColor === 'ecoponto' ? 550 :
                                    (info.typeColor === 'gerador' ? 430 :
                                        (info.typeColor === 'coletor' ? 430 : 500))
                            }
                            className='modal-map'
                            title={
                                info.typeColor === 'ecoponto' ? 'Detalhes do Ecoponto' :
                                    (info.typeColor === 'gerador' ? 'Detalhes do Gerador' :
                                        (info.typeColor === 'coletor' ? 'Detalhes do Coletor' :
                                            'Detalhes do Descarte'))
                            }
                        >
                            <ContentModalMap>
                                {
                                    info.typeColor === 'ecoponto' ?
                                        <div className="content-ecoponto">
                                            <div style={{ width: '50%' }}>
                                                <p>ID:</p>
                                                <span>{info.id}</span>
                                                <p>Nome:</p>
                                                <span>{info.name}</span>
                                                <p>Plano:</p>
                                                <span>{info.type === 'free' ? 'Gratuito' : 'Premium'}</span>
                                                <p>Tamanho:</p>
                                                <span>{sizeEcoponto(info.size)}</span>
                                                <p>Status:</p>
                                                <span>{statusEcoModal(info.status)}</span>
                                                <p>Contato:</p>
                                                <span>{info.phone ? info.phone : 'Não informado'}</span>
                                                <p>Observação:</p>
                                                <span>{info.obs ? info.obs : 'Não'}</span>
                                                <p>Endereço:</p>
                                                <span>{info.address}</span>
                                            </div>
                                            <div className="foto-ecoponto">
                                                <img src={info.photo ? info.photo : imgEcoDefault} alt="" />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                info.typeColor === 'gerador' ?
                                                    <div className="content-gerador">
                                                        <div style={{ width: '50%' }}>
                                                            <p>ID:</p>
                                                            <span>{info.id}</span>
                                                            <p>Nome:</p>
                                                            <span>{info.fullname}</span>
                                                            <p>CPF:</p>
                                                            <span>{info.document ? info.document : 'Não informado'}</span>
                                                            <p>E-mail:</p>
                                                            <span>{info.email ? info.email : 'Não informado'}</span>
                                                            <p>Contato telefônico:</p>
                                                            <span>{info.phone ? info.phone : 'Não informado'}</span>
                                                            <p>Endereço:</p>
                                                            <span>{info.address}</span>
                                                        </div>
                                                        <div className="avatar-gerador">
                                                            <img src={info.photo ? info.photo : img} alt="foto gerador" />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            info.typeColor === 'coletor' ?
                                                                <div className="content-coletor">
                                                                    <div style={{ width: '50%' }}>
                                                                        <p>ID:</p>
                                                                        <span>{info.id}</span>
                                                                        <p>Nome:</p>
                                                                        <span>{info.fullname}</span>
                                                                        <p>CPF:</p>
                                                                        <span>{info.document ? info.document : 'Não informado'}</span>
                                                                        <p>E-mail:</p>
                                                                        <span>{info.email ? info.email : 'Não informado'}</span>
                                                                        <p>Contato telefônico:</p>
                                                                        <span>{info.phone ? info.phone : 'Não informado'}</span>
                                                                        <p>Endereço:</p>
                                                                        <span>{info.address}</span>
                                                                    </div>
                                                                    <div className="avatar-coletor">
                                                                        <img src={info.photo ? info.photo : img} alt="foto gerador" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="content-descarte">
                                                                    <div style={{ width: '50%' }}>
                                                                        <p>ID:</p>
                                                                        <span>{info.id}</span>
                                                                        <p>Usuário:</p>
                                                                        <span>{info.user}</span>
                                                                        <p>Tipo:</p>
                                                                        <span>{info.type}</span>
                                                                        <p>Período:</p>
                                                                        <span>{info.shift}</span>
                                                                        <p>Status:</p>
                                                                        <span>{info.status}</span>
                                                                        <p>Observação:</p>
                                                                        <span>{info.note ? info.note : 'Não'}</span>
                                                                        <p>Endereço:</p>
                                                                        <span>{info.address}</span>
                                                                    </div>
                                                                    <div className="content-image-descarte">
                                                                        <Gallery
                                                                            noDataText="Coleta sem fotos"
                                                                            height={"30vh"}
                                                                            width={'100%'}
                                                                            initialItemWidth={170}
                                                                            stretchImages={false}
                                                                            showNavButtons={true}
                                                                            dataSource={info.image && info.image.map((pho) => { return pho.url })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </ContentModalMap>
                        </Popup>
                    </>
                ) : (
                    <></>
                )

            }
        </Container>
    );
}