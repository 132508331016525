import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';
import img from '../../assets/defaultuser.png';

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  // console.log(user);
  const history = useHistory();

  // const [photo, setPhoto] = useState(user.photo);

  // useEffect(() => {set})

  function navigateToProfile() {
    history.push("/profile");
  }
  const menuItems = useMemo(() => ([
    {
      text: 'Perfil',
      icon: 'user',
      onClick: navigateToProfile
    },
    {
      text: 'Sair',
      icon: 'runner',
      onClick: signOut
    }
  ]), [signOut]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <img
            src={!user.photo ? img : user.photo}
            className={'user-image'}
            alt="user-avatar" />
        </div>
        <div className={'user-name'}>{user.name}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={100}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
