import Form  from "devextreme-react/form";
import styled from "styled-components";

export const Container = styled.div`
    padding: 0 30px;
    max-width: 1880px;
    margin: 0 auto;
    
    
    .title-categories {
        text-align: center;
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1.2rem;
        color: #1C4738;
        margin: 1rem 0 1rem 0;
        padding: 0 30px;

        @media(max-width: 1365px) {
            margin: -5px;
            margin-bottom: -15px;
            font-size: 1rem;
        }
    }

    .button-form-eco {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        
        @media(max-width: 1365px) {
            margin-top: 0;
        }
    }
    
    .button-submit {
        font-family: 'Jost', sans-serif;
    }

    .google-input {
        padding: 0.93rem 1.25rem 1.25rem 1.25rem;
        margin-bottom: 30rem;

        p {
            margin: 0 0 0.5rem 1rem;
            font-family: 'Jost', sans-serif;
            font-weight: 400;
            color: #1C4738;
            font-size: 1rem;
        }
    }

    .css-1bvv025-control {
        border-color: none;
        box-shadow: none;
    }

    .division-form-map {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media(max-width: 1135px) {
            flex-direction: column;
        }

        form {
            width: 60%;
            @media(max-width: 1135px) {
                width: 100%;
            }
        }

        .maps {
            width: 39.2%;
            @media(max-width: 1135px) {
                width: 100%;
            }

            .title-map {
                position: fixed;
                margin-top: -30px;
                font-family: 'Jost', sans-serif;
                font-size: 1.2rem;
                color: #1C4738;

                span {
                    font-weight: 600;
                }

                @media(max-width: 1365px) {
                    font-size: 0.9rem;
                }
            }
        }

        .no-maps {
            width: 39.2%;
            height: 75vh;
            padding: 40px 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media(max-width: 1135px) {
                width: 100%;
            }

            p {
                font-family: 'Jost', sans-serif;
                font-size: 1.6rem;
                color: #1C4738;

                span {
                    font-weight: 600;
                }
            }
        }

        aside {
            width: 100%;
        }
    }

`;

export const Header = styled.div`
    padding: 10px;
`;

export const TextHeader = styled.p`
    margin: 0;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    color: #1C4738;
`;

export const FormRegisterEcopoint = styled(Form)`
    padding: 0 20px;

    .item-component-name {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        color: #1C4738;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
    }
    
    .item-component{
        margin-top: -1.8rem;
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        color: #1C4738;
        font-size: 0.8rem;
    }
`;

export const CatEcoPoints = styled.div`
    padding: 0 40px;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem;

    @media(max-width: 1540px) {   
        gap: 1rem;
    }

    @media(max-width: 1365px) {
        img {
            width: 70px;
        }
        gap: 1rem;
        margin-bottom: 1rem;
    }

   

    p {
        margin-bottom: 0;
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;
        @media(max-width: 1345px) {
            font-size:0.8rem;
            margin-right: 0;
        }
    }

    .cats {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .check-categories {
        margin: 1rem 0 0 0;
        @media(max-width: 1365px) {
            margin: 0.5rem 0 0 0;
        }
    }
    
`;

export const FormEcoPoint = styled.div`
    padding: 10px 30px;

    section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .div1 {
            width: 66.2%;
        }
        .div2 {
            width: 32.4%
        }
        @media(max-width: 800px) {
            flex-direction: column;
            .div1 {
                width: 100%;
                margin-right: 0;
            }
            .div2 {
                width: 100%;
            }
        }
    }

    .divisions {
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        gap: 1rem;
        
        @media(max-width: 800px) {
            grid-template-columns: 1fr;
            gap: 0.25rem;
        }
    }
`;