import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 10px 20px 20px 20px;
    margin-right: 20px;
    height: 170px;
    border: 0;

    @media(max-width: 880px) {
        /* width: 48%; */
        margin-right: 0;
        /* margin-bottom: 20px; */
    }

    @media(max-width: 600px) {
        margin-bottom: 5px;
        width: 100%;
        height: 200px;
    }
    @media(max-width: 1370px) {
        height: 185px;
    }

    @media(max-width: 1060px) {
        height: 170px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
`;

export const Title = styled.p`
    font-family: 'Jost', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: #1C4738;
    text-align: start;
`;

export const Info = styled.p`
    font-family: 'Jost', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-align: start;
    color: #1C4738;
    margin-top: 0;
`;

export const Underline = styled.div`
    width: 100%;
    height: 2px;
    background-color: #BEC7C7;
    margin-top: -30px;
    margin-bottom: 20px;
`;

export const Icon = styled.p`
    font-size: 40px;
`;

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;

    @media(max-width: 1370px) {
        flex-direction: column;
        margin-bottom: 10px;
    }

    @media(max-width: 1060px) {
        flex-direction: row;
    }

    /* @media(max-width: 600px) {
        flex-direction: column;
    } */
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Info1 = styled.div`
    font-family: 'Jost', sans-serif;
`;

export const Info2 = styled.div`
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    margin-left: 0.2rem;
`;
