import styled from "styled-components";

export const Container = styled.div`
    padding: 0 30px;
`;

export const ContentForm = styled.form`
    padding: 20px;

    .select-users-partner {
        margin-top:-12px;
    }

    .content-inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media(max-width: 800px) {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
    }

    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        color: #1C4738;
        margin-top: 2px;
    }

    .bottom-register-partner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 684px) {
            flex-direction: column;
        }
    }

    .uploader-partner {
        margin-top: 20px;
        background: rgba(245,245,245,9);
        border-radius: 0.25rem;
        width: 80%;

        @media(max-width: 800px) {
            width: 70%;
        }

        @media(max-width: 684px) {
            width: 100%;
        }
    }

    .button-submit-partner {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }
`;