import React from "react";
import Chart, { ArgumentAxis, CommonSeriesSettings, Grid, Point, Size, Tooltip, Label, Series, Legend } from "devextreme-react/chart";

import { Container, Title } from './styles';

export function Chart2({title, dataSource, bottonChart, values}) {
    return (
        <Container className="dx-card">
            <Title>{title}</Title>
            <Chart palette='Office' dataSource={dataSource} >
                <Size height={300} />
                <CommonSeriesSettings
                    type='spline'
                    argumentField={bottonChart}
                    hoverMode="allArgumentsPoints"
                >
                    <Point hoverMode="allArgumentPoints" />
                </CommonSeriesSettings>
                <ArgumentAxis
                    type="discrete"
                    valueMarginsEnabled={false}
                    discreteAxisDivisionMode="crossLabels"
                >
                    <Grid visible={false} />
                </ArgumentAxis>
                <Tooltip
                    enabled={true}
                    arrowLength={10}
                    color="#1C4738"
                    font={{color: "white", size: 15}}
                    shadow={{blur: 2, color: "black", opacity: 50, offsetY: 5, offsetX: 3}}
                    paddingTopBottom={12}
                    paddingLeftRight={12}
                    customizeTooltip={customizeToolTip}
                />
                <Label visible={true} />
                <Legend visible={true} verticalAlignment='bottom' horizontalAlignment='center' />
                {
                    values.map((item) => (
                        <Series
                        key={item.value}
                        valueField={item.value}
                        name={item.name}
                        />
                    ))
                }
            </Chart>
        </Container>
    );
}

function customizeToolTip(arg) {
    let user = arg.value.toString()
    return {text: `${user} `}
  }