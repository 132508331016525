import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 30px;

    header {
        padding: 10px;

        p {
            margin: 0;
            font-family: 'Jost', sans-serif;
            font-weight: 500;
            font-size: 2rem;
            color: #1C4738;
        }
    }

    .grid-list-ecopoints {
        font-family: 'Jost', sans-serif;
        color: #1C4738;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ContentModalEcopoint = styled.div`

    .form-edit-eco {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        aside {
            width: 40%;
        }
    }
    
    .header-modal-ecopoint-details {
        display: flex;
        flex-direction: row;
        @media(max-width: 1000px) {
            flex-direction: column;
        }
    }

    .buttons-modal {
        display: flex;
        flex-direction: row;
        margin: 0;
    }

    .div-button-back-activate {
        display: flex;
        justify-content: flex-end;
        
        .button-back {
            margin-top:0.3rem;
            margin-bottom: -5rem;
            margin-right: 0.5rem;
            color: #ffffff;
        }

    }

    .header-activate {
        background-image: linear-gradient(to right,#1C4738,#ff5722);
        height: 3rem;
        align-items:center;
        

        img {
            width: 5rem;
            margin: 0 auto;
            width: 120px;
            margin-top: -7px
        }
    }

    .content-modal-activation {
        margin-top: 2rem;
        
        p {
            font-family: 'Jost', sans-serif;
            font-weight: 500;
            font-size: 0.8rem;
            color: #1C4738;
        }

        header {
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: flex-end;

            .button-back {
                margin-right: 1rem;
            }
        }

        .button-activation{
            margin: 0 1rem;
            width: 50%;
            
        }

        .radio-group-active {
            font-family: 'Jost', sans-serif;
        }

        .radio-group-div {
            background: rgba(245,245,245,9);
            border-radius: 0.25rem;
            margin-top: -12px;
            height: 3rem;
            display: flex;
            flex-wrap: wrap;
            .radio-group {
                margin-left: 10px;
                display: flex;
                flex-wrap: wrap;
            }
        }

        .first-line-activation-eco {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            @media(max-width: 900px) {
                grid-template-columns: 1fr
            }
        }

        .uploader-ecopoint {
            background: rgba(245,245,245,9);
            border-radius: 0.25rem;
        }

        .button-active-eco {
            margin-top: 15px;
            text-align: center;
        }

        .radio-group-activation {
            margin-top: -10px;
        }

        .div-activation-eco {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 12px;
            background: rgba(245,245,245,9);
            height: 4.3rem;
            border-radius: 0.25rem;

            .inactive-eco {
                font-family: 'Jost', sans-serif;
                font-size: 1.7rem;
                color: gray;
                margin-right: 0.8rem;
                margin-left: 0.8rem;
            }

            .active-eco {
                font-family: 'Jost', sans-serif;
                font-size: 1.7rem;
                color: #ff5722;
                margin-left: 0.8rem;
            }
        }
    }

    p {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #1C4738;

        span {
            font-family: 'Jost', sans-serif;
            font-weight: 400;
            color: #1C4738;
        }
    }

    img {
        border-radius: 0.5rem;
        height: 18rem;
        @media(max-width: 600px) {
            height: 15rem;
        }
        @media(max-width: 570px) {
            height: 14rem;
        }
        @media(max-width: 530px) {
            height: 12rem;
        }
    }

    header {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: flex-end;

        .button-back {
            margin-right: 1rem;
        }
    }

    .content-edit-eco {

        width: 58%;

        .first-section-edit-eco {
            display: flex;
            flex-direction: row;
        }

        .sections-edit-eco {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
            margin-top: 0.5rem;
        }

        > div {
            margin-bottom: 0.5rem;
        }

                                                            
        .title-categories-edit {
            margin: 0.5rem auto 0 auto;
            text-align: center;
            font-size: 1.2rem;
        }

        .edit-cat-eco {
            padding: 0 40px;
            margin-bottom: 1.5rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;

            @media(max-width: 1320px) {
                gap: 5rem;
            }

            @media(max-width: 1160px) {
                gap: 2rem;
            }

            p {
                margin-bottom: 0;
                font-family: 'Jost', sans-serif;
                font-weight: 500;
                font-size: 1rem;
                color: #1C4738;
            }

            .cats {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 5rem;
                    height: 8rem;
                }
            }

            .check-categories {
                margin: 1rem 0 0 0;
            }
        }

        .last-section-edit-eco {
            display: flex;
            flex-direction: row;

            p {
                font-family: 'Jost', sans-serif;
                font-weight: 500;
                font-size: 0.8rem;
                color: #1C4738;
                margin: 0
            }
        }

        .button-submit-edit-eco {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2rem 0;

        }
    }
`;