import styled from "styled-components";

export const Container = styled.div`
    .container {
        display: flex;
        flex-direction: row;
        .content {
            width: 35%;
            margin: 0 20px;
            padding: 62px;
            display: flex;
            flex-direction: row;
            height:250px;
            .photo-profile {
                width: 120px;
                img {
                    height: 120px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .information-user { 
                margin-left: 20px;
                p {
                    font-family: 'Jost', sans-serif;
                    font-weight: 500;
                    font-size: 1rem;
                    color: #1C4738;
                    margin: 0;
                    span {
                        font-family: 'Jost', sans-serif;
                        font-weight: 400;
                        color: #1C4738;
                    }
                }   
            }
        }
        .content-2 {
            width: 70%;
            height:250px;
            padding: 40px;
            margin: 0 20px 0 0;
            
            span {
                font-family: 'Jost', sans-serif;
                font-size: 1rem;
            }
            .file-and-button{
                display: flex;
                flex-direction: row;
                align-items: center;
                .file-uploader {
                    width: 50%;
                }
                .button-submit {
                    margin-left: 50px;
                    width: 200px;
                }
            }
            
        }
    }
    
`;