import React, { useState, useEffect, useCallback, useRef } from "react";
import { Column, Export, Item, Paging, SearchPanel, Selection, Toolbar } from "devextreme-react/data-grid";
import { Button, Popup, Gallery, ScrollView } from "devextreme-react";
import 'devextreme/data/odata/store';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import {
  Container,
  Header,
  TableCollects,
  RowModal,
  ContentModal
} from './styles';

import api from '../../services/api';
import useCardCollect from "../../hooks/useCardCollect";
import usePagination from "../../hooks/usePagination";

import { ActiveStatus } from "../../components/ActiveStatus";
import { MapsGoogleAll } from "../../components/maps-google-all";
import { CardCollect } from '../../components/cardcollect';
import { HeaderText } from "../../components/header-text";
import { LoadTrash } from "../../components/load-trash";

export function Collects() {
  const [collect, setCollect] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [collectSelected, setCollectSelected] = useState([]);
  const [view, setView] = useState('table');

  const { collects, loadCollects, loadingCards, offset } = useCardCollect(10);
  const { actualPage, setActualPage } = usePagination()

  const dataGridRef = useRef(null);

  const exportGridPDF = useCallback(() => {
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      autoTableOptions: {
        theme: 'striped',
        headStyles: {
          textColor: '#ffffff',
          valign: 'middle',
          fontStyle: 'bold',
        },
        bodyStyles: {
          lineWidth: 0
        }
      }
    }).then(() => {
      doc.save('Descartes.pdf');
    })
  }, [])

  const onExportingExcel = useCallback((e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGridToExcel({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Descartes.xlsx')
      })
    })
    e.cancel = true;
  }, [])

  async function loadData() {
    setLoading(true);
    await api.get('/adm/collections').then(res => {
      // console.log('teste',res.data)
      const data = res.data.map(col => {
        return {
          id: col.id,
          fullname: col.user.fullname,
          name: col.user.name,
          photo: col.user.photo,
          date_collect: col.created_at,
          date: format(new Date(col.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
          type_collect: col.type,
          shift: col.shift,
          status: col.status,
          quantity: col.quantity,
          addressPeq: `${col.address.district}, ${col.address.city}, ${col.address.state}`,
          address: `${col.address.street}, ${col.address.number}, ${col.address.district}, ${col.address.city}, ${col.address.state}`,
          local: col.address.local,
          lat: col.address.lat,
          lng: col.address.long,
          note: col.note,
          categories: col.categories,
          photoCollect: col.images,
          discart: col?.discart?.status ? col.discart.status : 'Ecoponto não selecionado'
        }
      })
      // console.log(data)
      setCollect(data)
    })
    setLoading(false);
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    loadCollects(actualPage)
  }, [actualPage])

  const limit = 10;
  const MAX_ITEMS = 9;
  const MAX_LEFT = (MAX_ITEMS - 1) / 2;

  const current = offset ? (offset / limit) + 1 : 1;
  const pages = Math.ceil(collect.length / limit);
  const first = Math.max(current - MAX_LEFT, 1);
  const last = current + MAX_LEFT;

  function status(data) {
    if (data.value === 'pendente') {
      return <ActiveStatus text="pendente" color="#ED7A4D" colorText="black" />
    }
    if (data.value === 'cancelada') {
      return <ActiveStatus text="cancelada" color="#BB2020" colorText="white" />
    }
    if (data.value === 'agendada') {
      return <ActiveStatus text="agendada" color="#506AD4" colorText="white" />
    }
    if (data.value === 'finalizada') {
      return <ActiveStatus text="finalizada" color="#3EB595" colorText="white" />
    }
  }

  function discart(data) {
    if (data.value === 'finalizada') {
      return <ActiveStatus text="check-in realizado" color="#3EB595" colorText="white" />
    }
    if (data.value === 'pendente') {
      return <ActiveStatus text="check-in pendente" color="#ED7A4D" colorText="black" />
    }
    if (data.value === 'Ecoponto não selecionado') {
      return <ActiveStatus text="Ecoponto não selecionado" color="#506AD4" colorText="white" />
    }
  }

  function discartModal(data) {
    if (data === 'finalizada') {
      return <ActiveStatus text="check-in realizado" color="#3EB595" colorText="white" />
    }
    if (data === 'pendente') {
      return <ActiveStatus text="check-in pendente" color="#ED7A4D" colorText="black" />
    }
    if (data === 'Ecoponto não selecionado') {
      return <ActiveStatus text="Ecoponto não selecionado" color="#506AD4" colorText="white" />
    }
  }

  function onSelectionChanged({ selectedRowsData }) {
    const data = selectedRowsData[0];
    setModal(true)
    setCollectSelected(data)
  }

  function onCloseModal() {
    setModal(false)
  }

  return (
    <Container>
      <Header>
        <HeaderText text='Descartes' />
        <Button className="buttons-header-collect" text="TABELA" type={view === 'table' ? 'default' : 'normal'} onClick={() => setView('table')} />
        <Button className="buttons-header-collect" text="CARDS" type={view === 'cards' ? 'default' : 'normal'} onClick={() => setView('cards')} />
      </Header>
      {loading &&
        <LoadTrash />
      }
      {!loading &&
        <>
          {
            view === 'table' ?
              <>
                <TableCollects
                  dataSource={collect}
                  ref={dataGridRef}
                  keyExpr='id'
                  onExporting={onExportingExcel}
                  columnAutoWidth={true}
                  columnHidingEnabled={true}
                  allowColumnResizing={true}
                  dateSerializationFormat="ABNT NBR 5892"
                  scrolling={{ mode: 'standard', rowRenderingMode: 'standard' }}
                  onSelectionChanged={onSelectionChanged}
                >
                  <Selection mode="single" />
                  <Export enabled={true} />
                  <SearchPanel visible={true} width={250} />
                  <Paging defaultPageIndex={0} defaultPageSize={30} />
                  <Column caption="ID" alignment="center" dataField="id" width={70} />
                  <Column caption="NOME GERADOR" alignment="center" dataField="name" width={150} />
                  <Column caption="DATA" alignment="center" dataField="date_collect" dataType="date" width={120} />
                  <Column caption="TIPO" alignment="center" dataField="type_collect" width={100} />
                  {/* <Column caption="PERÍODO" alignment="center" dataField="shift" width={100} /> */}
                  <Column caption="STATUS" alignment="center" dataField="discart" width={220} cellRender={discart} />
                  {/* <Column caption="SACOS" alignment="center" dataField="quantity" width={80} /> */}
                  <Column caption="ENDEREÇO" alignment="center" dataField="address" />
                  <Toolbar>
                    <Item>
                      <Button icon="exportpdf" onClick={exportGridPDF} />
                    </Item>
                    <Item name='exportButton' />
                    <Item name='searchPanel' />
                  </Toolbar>
                </TableCollects>
                <Popup
                  visible={modal}
                  closeOnOutsideClick={true}
                  showCloseButton={true}
                  showTitle={true}
                  container=".dx-viewport"
                  onHiding={onCloseModal}
                  className="modal-collects"
                  title={`Detalhes do descarte (ID: ${collectSelected.id})`}
                >
                  <ScrollView>
                    <ContentModal style={{ paddingRight: "0.5rem" }}>
                      <div style={{ width: '50%' }}>
                        <RowModal>
                          <p>{`NOME GERADOR: `}<span>{` ${collectSelected.fullname}`}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`DATA DA COLETA: `}<span>{` ${collectSelected.date}`}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`TIPO: `}<span>{` ${collectSelected.type_collect}`}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`PERÍODO: `}<span>{` ${collectSelected.shift}`}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`STATUS: `}<span>{discartModal(collectSelected.discart)}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`SACOS: `}<span>{` ${collectSelected.quantity}`}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`ENDEREÇO: `}<span>{` ${collectSelected.address}`}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`COMENTÁRIO: `}<span>{collectSelected.note === null ? 'Não' : collectSelected.note}</span></p>
                        </RowModal>
                        <RowModal>
                          <p>{`CATEGORIAS: `}<span>{collectSelected.categories && collectSelected.categories.map((cat, index) => { return index === 0 ? cat.description : `, ${cat.description}` })}</span></p>
                        </RowModal>
                      </div>
                      <div style={{ width: "50%" }}>
                        <p>Localização da coleta:</p>
                        <MapsGoogleAll position={collectSelected} />
                        <p>Fotos da coleta:</p>
                        <Gallery
                          style={{ marginTop: "1rem" }}
                          noDataText="Coleta sem fotos"
                          height={400}
                          width={'100%'}
                          stretchImages={true}
                          showNavButtons={true}
                          dataSource={collectSelected.photoCollect && collectSelected.photoCollect.map((pho) => { return pho.url })}
                        />
                      </div>
                    </ContentModal>
                  </ScrollView>
                </Popup>
              </>
              :
              <>
                {
                  loadingCards &&
                  <LoadTrash />
                }
                {
                  !loadingCards &&
                  <>
                    <div className="card-collect">
                      {
                        collects.map((item) => (
                          <CardCollect photo={item} key={item.id} />
                        ))
                      }
                    </div>
                    <div className="content-buttons-card-collects" >
                      {
                        Array.from({ length: Math.min(MAX_ITEMS, pages) })
                          .map((_, index) => {
                            if (last <= pages) {
                              return index + first
                            } else {
                              return index + (pages - MAX_ITEMS + 1)
                            }
                          }
                          )
                          .map((page) => (
                            <Button
                              value={page}
                              key={page}
                              text={page}
                              type={page === actualPage ? 'default' : 'normal'}
                              onClick={() => { setActualPage(page) }}
                              className='button-paging-cards-collects'
                            />
                          ))
                      }
                    </div>
                  </>
                }
              </>
          }
        </>
      }
    </Container>
  );
}