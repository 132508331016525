import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import api from '../../services/api.js';

import {
  Container
} from './styles.js';

import img from '../../assets/defaultuser.png';
import { IoMdCloseCircle } from 'react-icons/io';
import { BsCheckCircleFill } from 'react-icons/bs';
import { useAuth } from '../../contexts/auth';

import { HeaderText } from '../../components/header-text';
import { Button, FileUploader, Toast } from 'devextreme-react';
import { LoadTrash } from '../../components/load-trash/index.jsx';

export function ProfilePage() {

  const { user, updateUser } = useAuth();

  const [ip, setIp] = useState();
  const [latAtual, setLatAtual] = useState();
  const [lngAtual, setLngAtual] = useState();
  const [avatar, setAvatar] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [type, setType] = useState('');
  const [situation, setSituation] = useState('');

  const avatarUpdate = new FormData();

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIp(res.data.IPv4);
    setLatAtual(res.data.latitude);
    setLngAtual(res.data.longitude);
  }

  useEffect(() => {
    getData();
  }, [])

  function typeUser(data) {
    if (data === 1) {
      return 'Administrador'
    }
    if (data === 3) {
      return 'Ecoponto'
    }
    if (data === 4) {
      return 'Coletor'
    }
    if (data === 5) {
      return 'Gerador'
    }
  }

  const onSubmit = useCallback(async (e) => {
    setLoading(true);

    avatarUpdate.append('avatar', avatar);

    try {
      const response = await api.post(`/users/avatar?ip=${ip}&lat=${latAtual}&long=${lngAtual}`, avatarUpdate)
      processSuccess('success')
      setLoading(false);
      updateUser(response.data)
      setTimeout(() =>{window.location.reload()},'5000')
    } catch (e) {
      processFail('fail')
      setLoading(false);
    }
  }, [ip, latAtual, lngAtual, avatar, loading, avatarUpdate, user])

  function processFail(situation) {
    setToast(true);
    setType('error');
    setSituation(situation)
  }

  function processSuccess(situation) {
    setToast(true);
    setType('success');
    setSituation(situation);
  }

  function onCloseToast() {
    setToast(false);
  }

  return (
    <Container>
      {
        loading &&
        <LoadTrash />
      }
      {
        !loading &&
        <>
          <HeaderText text='Perfil' />
          <div className='container'>
            <div className='dx-card content'>
              <div className='dx-card photo-profile'>
                <img alt={'photo-user'} src={!user.photo ? img : user.photo} />
              </div>
              <div className='information-user'>
                <p>ID: <span>{user.id}</span></p>
                <p>NOME: <span>{user.fullname}</span></p>
                <p>E-MAIL: <span>{user.email}</span></p>
                <p>TIPO USUÁRIO: <span>{typeUser(user.user_type_id)}</span></p>
                <p>CONTATO: <span>{user.phone}</span></p>
              </div>
            </div>
            <div className='dx-card content-2'>
              <span>Mudar foto de perfil:</span>
              <div className='file-and-button'>
                <div className='dx-card file-uploader'>
                  <FileUploader
                    accept='image/*'
                    multiple={false}
                    allowCanceling={true}
                    hint='Selecione uma imagem'
                    hoverStateEnabled={true}
                    invalidFileExtensionMessage='Arquivo não permitido.'
                    uploadMode='useForm'
                    onValueChanged={(e) => { setAvatar(e.value[0]) }}
                  />
                </div>
                <Button className='button-submit' type='success' text='carregar' onClick={(e) => { onSubmit(e) }} />
              </div>
            </div>
          </div>
        </>
      }
      <Toast
        visible={toast}
        type={type}
        onHiding={onCloseToast}
        contentComponent={() => RenderToast(situation)}
        displayTime={4000}
        height='85px'
        animation={{
          hide: { type: 'slide', duration: 400, to: { position: { my: 'top', at: 'bottom', of: window } } },
          show: { type: 'slide', duration: 400, from: { position: { my: 'top', at: 'bottom', of: window } } }
        }}
      />
    </Container>
  );
}

function RenderToast(situation) {
  return (
    <>
      {
        situation === 'fail' &&
        <>
          <IoMdCloseCircle color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
          <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Falha no processo.</p>
        </>
      }
      {
        situation === 'success' &&
        <>
          <BsCheckCircleFill color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
          <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Foto do perfil atualizada com sucesso.</p>
        </>
      }
    </>
  );
}
