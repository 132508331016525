import { Button } from "devextreme-react";
import { css } from "styled-components";
import styled from "styled-components";

export const Container = styled.div`
    margin-right: -8px;

    @media(max-width: 880px) {
        margin-right: 5px;
    }

`;

export const ContentCards = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 20px;

    @media(max-width: 1060px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-right: 20px;
    }

    @media(max-width: 880px) {
        flex-wrap: wrap;
        margin-bottom: 0;
        padding: 0 20px;
    }

    @media(max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
`;

export const ContentChart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 20px;

    @media(max-width: 950px) {
        flex-direction: column;
    }
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: 1015px) {
        flex-direction: column;
        align-items: start;
        margin-bottom: 10px;

        div {
            flex-wrap: wrap;
        }
    }

    @media(max-width: 645px) {
        margin-bottom: 0;
    }
`;

export const TextHeaderMap = styled.p`
    margin: 0;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    color: #1C4738;
    margin-bottom: 5px;
    margin-right: 10px;
`;

export const ContentMap = styled.div`
    padding: 0 20px;
    margin-top: -10px;
    margin-right: 20px;
    margin-bottom: 20px;
`;

export const ButtonSelectMap = styled(Button)`
    margin-right: 0.5rem;
    &.dx-button.dx-button-default{
        ${(props) =>
        props.title === 'coleta' &&
        css`
            background-color: #0059A9;
        `}
        ${(props) =>
        props.title === 'gerador' &&
        css`
            background-color: #EC2023;
        `}
        ${(props) =>
        props.title === 'ecoponto' &&
        css`
            background-color: #F4743A;
        `}
        ${(props) =>
        props.title === 'coletor' &&
        css`
            background-color: #3CB34C;
        `}
    }

    @media(max-width: 645px) {
        margin-bottom: 10px;
    }
  
`;

export const ContentLastUsersCollects = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 20px;
    margin-right: 20px;

    @media(max-width: 980px) {
        display: flex;
        flex-direction: column;
    }
`;