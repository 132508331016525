import React, { useEffect, useState, useCallback, useRef } from 'react';
import 'devextreme/data/odata/store';
import { Button, Popup, ScrollView } from "devextreme-react";
import { Column, Item, SearchPanel, Selection, Toolbar, Export } from 'devextreme-react/data-grid';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es'

import {
  Container,
  TextHeader,
  Table,
  PhotoProfile,
  Header,
  ContentModal,
  RowModal
} from './style';

import api from '../../services/api';
import img from '../../assets/defaultuser.png';

import { MapsGoogleAll } from '../../components/maps-google-all';
import { LoadTrash } from '../../components/load-trash';

export function Users() {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [type, setType] = useState('todos');
  const [usersFilter, setUsersFilter] = useState([]);

  const dataGridRef = useRef(null);

  const exportGridPDF = useCallback(() => {
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
      autoTableOptions: {
        theme: 'striped',
        headStyles: {
          textColor: '#ffffff',
          valign: 'middle',
          fontStyle: 'bold'
        },
        bodyStyles: {
          lineWidth: 0
        }
      }
    }).then(() => {
      doc.save('Usuários.pdf')
    })
  }, [])

  const onExportingExcel = useCallback((e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGridToExcel({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Usuários.xlsx')
      })
    })
    e.cancel = true;
  }, [])

  async function loadData() {
    setLoading(true);
    await api.get('/adm/users?address=1').then(res => {
      const data = res.data.map(us => {
        return {
          id: us.id,
          name: us.name,
          fullname: us.fullname,
          email: us.email,
          phone: us.phone,
          usertypeid: us.user_type_id,
          type: us.user_type.type,
          date: us.created_at,
          dateFormatted: format(new Date(us.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
          photo: us.photo,
          active: us.active,
          document: us.document,
          origin: us.origin,
          lat: us.address?.lat,
          lng: us.address?.long,
          typeColor: us.user_type_id === 5 ? "gerador" : ((us.user_type_id === 4 || us.user_type_id === 3) ? "coletor" : "admin"),
          birth: format(new Date(us.birth), 'dd/MM/yyyy'),
          addressTable: us.address?.district === undefined || us.address?.city === undefined || us.address?.state === undefined ? 'Sem endereço cadastrado' : `${us.address?.district}, ${us.address?.city}, ${us.address?.state}`,
          address: `${us.address?.street}, ${us.address?.number}, ${us.address?.district}, ${us.address?.city}, ${us.address?.state}`
        }
      })
      setUsers(data)
    })
    setLoading(false)
  }

  function picture(data) {
    if (data.value === null) {
      return <PhotoProfile src={img} />
    } else {
      return <PhotoProfile src={data.value} />
    }
  }

  function addressOk(data) {
    if (data.value !== 'undefined, undefined, undefined') {
      return <p>{data.value}</p>
    } else {
      return <p style={{ fontWeight: '500' }}>CADASTRO INCOMPLETO</p>
    }
  }

  function active(data) {
    if (data.value === 1) {
      return <div style={{ backgroundColor: "#3EB595", borderRadius: "5px" }} ><p style={{ color: "#fff", padding: "0px", margin: '0px' }}>Sim</p></div>
    } else {
      return <div style={{ backgroundColor: "#BB2020", borderRadius: "5px" }} ><p style={{ color: "#fff", padding: "0px", margin: '0px' }}>Não</p></div>
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      handleType(type)
    }
  }, [users])

  const handleType = useCallback((t) => {
    setType(t)
    if (t !== 'todos') {
      let f = users.filter(m => {
        return m.typeColor === t
      })
      setUsersFilter(f)
    } else {
      setUsersFilter(users)
    }
  }, [users])

  function onSelectionChanged({ selectedRowsData }) {
    const data = selectedRowsData[0];

    if (data !== undefined) {
      setUsersSelected(data)
      setModal(true)
    }
  }

  function onCloseModal() {
    setModal(false)
  }
  
  return (
    <Container>
      <Header>
        <TextHeader>Usuários</TextHeader>
        <Button
          className='buttons-head-users'
          text='todos'
          type={type === 'todos' ? 'default' : 'normal'}
          onClick={() => { handleType('todos') }}
        />
        <Button
          className='buttons-head-users'
          text='geradores'
          type={type === 'gerador' ? 'default' : 'normal'}
          onClick={() => { handleType('gerador') }}
        />
        <Button
          className='buttons-head-users'
          text='coletores'
          type={type === 'coletor' ? 'default' : 'normal'}
          onClick={() => { handleType('coletor') }}
        />
        <Button
          className='buttons-head-users'
          text='administrador'
          type={type === 'admin' ? 'default' : 'normal'}
          onClick={() => { handleType('admin') }}
        />
      </Header>
      {loading &&
        <LoadTrash />
      }
      {!loading &&
        <>
          <Table
            dataSource={usersFilter}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnResizing={true}
            dateSerializationFormat="ABNT NBR 5892"
            scrolling={{ mode: 'standard', rowRenderingMode: 'standard' }}
            onSelectionChanged={onSelectionChanged}
            ref={dataGridRef}
            keyExpr='id'
            onExporting={onExportingExcel}
          >
            <Selection mode="single" />
            <SearchPanel visible={true} width={250} />
            {/* <Paging defaultPageIndex={0} defaultPageSize={30} /> */}
            <Column caption="ID" alignment="center" dataField="id" width={70} />
            <Column caption="FOTO" alignment="center" dataField="photo" cellRender={picture} width={75} allowSorting={false} allowExporting={false} />
            <Column caption="NOME" alignment="center" dataField="name" width={150} />
            <Column caption="E-MAIL" alignment="center" dataField="email" width={170} />
            <Column caption="CONTATO" alignment="center" dataField="phone" width={120} />
            <Column caption="TIPO" alignment="center" allowExporting={false} dataField="type" width={120} />
            <Column caption="DATA CADASTRO" alignment="center" dataField="date" dataType="date" width={140} />
            <Column caption="ATIVO" alignment="center" dataField="active" cellRender={active} width={75} allowExporting={false} />
            <Column caption="ENDEREÇO" alignment="center" dataField="addressTable" cellRender={addressOk} />
            <Toolbar>
              <Item>
                <Button icon='exportpdf' onClick={exportGridPDF} />
              </Item>
              <Item name='exportButton' />
              <Item name='searchPanel' />
            </Toolbar>
            <Export enabled={true} />
          </Table>
          <Popup
            visible={modal}
            closeOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            width={usersSelected.address === "undefined, undefined, undefined, undefined, undefined" ? '40vw' : '70vw'}
            height={usersSelected.address === "undefined, undefined, undefined, undefined, undefined" ? '60vh' : '90vh'}
            container=".dx-viewport"
            onHiding={onCloseModal}
            className="modal-users"
            title={`Detalhes do usuário`}
          >
            <ScrollView>
              <ContentModal>
                <div style={{ width: '50%' }}>
                  <RowModal>
                    <p>ID: <span>{usersSelected?.id}</span></p>
                    <p>NOME: <span>{usersSelected?.fullname}</span></p>
                    <p>E-MAIL: <span>{usersSelected?.email}</span></p>
                    <p>CONTATO: <span>{usersSelected?.phone === null ? 'Não informado' : usersSelected?.phone}</span></p>
                    <p>CPF: <span>{usersSelected?.document === null ? 'Não informado' : usersSelected?.document}</span></p>
                    <p>TIPO: <span>{usersSelected?.type}</span></p>
                    <p>DATA DE CRIAÇÃO: <span>{usersSelected?.dateFormatted}</span></p>
                    <p>STATUS: <span>{usersSelected?.active === 1 ? 'Ativo' : 'Inativo'}</span></p>
                    <p>ENDEREÇO: <span>{usersSelected?.address === 'undefined, undefined, undefined, undefined, undefined' ? 'Não informado' : usersSelected?.address}</span></p>
                    <p>DATA DE NASCIMENTO: <span>{usersSelected?.birth}</span></p>
                    <p>ORIGEM DO CADASTRO: <span>{usersSelected?.origin === 'web' ? 'Web' : 'Celular'}</span></p>
                  </RowModal>
                </div>
                <div className='div-photo' style={{ width: '50%' }}>
                  <img className='user-avatar' src={usersSelected?.photo === null ? img : usersSelected?.photo} alt="Avatar Usuário" />
                </div>
              </ContentModal>
              <div>
                {
                  usersSelected?.address !== "undefined, undefined, undefined, undefined, undefined" &&
                    
                    <div style={{ paddingRight: "0.7rem" }}>
                      <MapsGoogleAll position={usersSelected} />
                    </div>
                }
              </div>
            </ScrollView>
          </Popup>
        </>
      }
    </Container>
  );
}
