import styled from "styled-components";

export const Container = styled.div`
    label {
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        color: #1C4738;
    }

    input {
        width: 100%;
        height: 3rem;
        border: none;
        border-radius: 0.25rem;
        background: rgba(245,245,245,1);
        border-bottom: 1px solid rgba(0,0,0,0.45);
        font-size: 1rem;
        font-family: 'Jost', sans-serif;
        color: #1C4738;
        padding-left: 1rem;

        &:focus-visible {
            outline: none;
            border-bottom: 2px solid #ff5722;
        }

        &:hover {
            background: rgba(0,0,0,.09);
            transition: 0.3s ease-in;
        }
    }
`;