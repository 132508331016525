import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
    Container,
    Header,
    TextHeader,
    FormEcoPoint,
    CatEcoPoints,
} from './styles';

import { google } from "../../services/google";
import api from "../../services/api";
import { correios } from "../../services/correio";
import axios from "axios";
import { Button, CheckBox, LoadIndicator, Toast } from "devextreme-react";
import ButtonOptions from 'devextreme-react/button';

import catMetal from '../../assets/metal-2.svg';
import catElet from '../../assets/eletronicos.svg'
import catPapel from '../../assets/papeis.svg';
import catVidro from '../../assets/vidros.svg';
import catPlastico from '../../assets/plastico.svg';
import catOutros from '../../assets/othes.svg';
import { FaMapMarkedAlt } from 'react-icons/fa';

import { InputForm, InputFormMask } from "../../components/InputForm";
import { MapsGoogleRegisterEco } from "../../components/maps-google-register-eco";
import { MapsGoogleRegisterEcoStreetView } from "../../components/maps-google-register-eco-street-view";
import { LoadTrash } from "../../components/load-trash";
import { BsFillExclamationCircleFill, BsCheckCircleFill } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';

export function RegisterEcopoint() {

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingIndicator, setLoadingIndicator] = useState(false)
    const [categories, setCategories] = useState([]);
    const [cep, setCep] = useState('');
    const [street, setStreet] = useState('');
    const [streetEdit, setStreetEdit] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [ip, setIp] = useState('');
    const [location, setLocation] = useState({});
    const [latAtual, setLatAtual] = useState('');
    const [lngAtual, setLngAtual] = useState('');
    const [metal, setMetal] = useState(true);
    const [papel, setPapel] = useState(true);
    const [vidro, setVidro] = useState(true);
    const [plastico, setPlastico] = useState(true);
    const [eletronico, setEletronico] = useState(true);
    const [outros, setOutros] = useState(true);
    const [value, setValue] = useState(false);
    const [obs, setObs] = useState('');
    const [phone, setPhone] = useState('');
    const [toast, setToast] = useState(false);
    const [type, setType] = useState('');
    const [situation, setSituation] = useState('');

    const history = useHistory();

    //creating function to load ip address from the API
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data);
        setIp(res.data.IPv4);
        setLatAtual(res.data.latitude);
        setLngAtual(res.data.longitude);
    }

    const getAddress = useCallback(async () => {
        if (cep.length === 8) {
            await correios.get(`/${cep}/json/`)
                .then((res) => {
                    if (res.data.erro !== 'true') {
                        setStreet(res.data.logradouro)
                        setDistrict(res.data.bairro)
                        setCity(res.data.localidade)
                        setState(res.data.uf)
                        setStreetEdit(res.data.logradouro?.split(" ").join("+"))
                    } else {
                        // notify('Endereço não encontrado via CEP', 'error', 4000)
                        cepNoExists('cepFail')
                    }
                })
        }
    }, [cep])

    useEffect(() => {
        if (cep.length === 8) {
            getAddress()
        }
    }, [cep])

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
    }, [])

    async function loadData() {
        await api.get('/categories').then(res => {
            const data = res.data.map(cat => {
                return {
                    id: cat.id,
                    description: cat.description
                }
            })
            setCategories(data)
        })
    }

    useEffect(() => { loadData() }, [])

    const loadLatLong = useCallback(async () => {
        await google.get(`/json?address=${number}+${streetEdit},+${district},+${city},+${state},+BR&key=${process.env.REACT_APP_GOOGLE_API}`)
            .then((res) => {
                setLat(res.data.results[0].geometry.location.lat)
                setLng(res.data.results[0].geometry.location.lng)
                // console.log("lat: ", res.data.results[0].geometry.location.lat, " e long: ", res.data.results[0].geometry.location.lng)
            })
    }, [number, streetEdit, district, city, state])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (number !== '' && streetEdit !== '' && district !== '' && city !== '' && state !== '') {
                loadLatLong();
                setValue(true);
            }
        }, '2000')
        return () => clearTimeout(timer);
    }, [number, streetEdit, district, city, state])


    useEffect(() => {
        setStreetEdit(street.split(" ").join("+"))
    }, [street])

    useEffect(() => {
        setLocation({ latCenter: lat, lngCenter: lng, latMarker: lat, lngMarker: lng })
        // console.log('location: ', location)
    }, [lat, lng])

    function onCloseToast() {
        setToast(false)
    }

    function selectionCategory(situation) {
        setToast(true);
        setType('error');
        setSituation(situation)
    }

    function registerEcoFail(situation) {
        setToast(true);
        setType('error');
        setSituation(situation)
    }

    function registerEcoSuccess(situation) {
        setToast(true);
        setType('success');
        setSituation(situation)
    }

    function cepNoExists(situation) {
        setToast(true);
        setType('error')
        setSituation(situation)
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoadingIndicator(true);
        let cat;
        let catSelect = []
        if (metal) {
            cat = categories.filter(c => { return c.description === 'Metal' })
            catSelect.push(cat[0].id)
        }
        if (papel) {
            cat = categories.filter(c => { return c.description === 'Papel' })
            catSelect.push(cat[0].id)
        }
        if (vidro) {
            cat = categories.filter(c => { return c.description === 'Vidro' })
            catSelect.push(cat[0].id)
        }
        if (plastico) {
            cat = categories.filter(c => { return c.description === 'Plástico' })
            catSelect.push(cat[0].id)
        }
        if (eletronico) {
            cat = categories.filter(c => { return c.description === 'Eletrônicos' })
            catSelect.push(cat[0].id)
        }
        if (outros) {
            cat = categories.filter(c => { return c.description === 'Outros' })
            catSelect.push(cat[0].id)
        }
        if (catSelect.length === 0) {
            setLoading(false)
            setLoadingIndicator(false)
            return selectionCategory('categoryFail')
        }

        const response = await api.post('/ecopoints', {
            name,
            categories: catSelect,
            street,
            number,
            district,
            city,
            state,
            zipcode: cep,
            lat: `${location.latMarker}`,
            long: `${location.lngMarker}`,
            type: "casa",
            local: "ecoponto",
            ip,
            lat_atual: `${latAtual}`,
            long_atual: `${lngAtual}`,
            obs,
            phone: phone?.replace(/\D/g, "")
        })

        if (!response) {
            setLoading(false);
            setLoadingIndicator(false);
            return registerEcoFail('registerEcoFail');
        } else {
            setLoadingIndicator(false);
            registerEcoSuccess('registerEcoSuccess')
        }
        setTimeout(() => {
            history.push('/listecopoints')
        },'5000')


    }, [name, street, number, district, city, state, cep, lat, lng, ip, latAtual, lngAtual, metal, plastico, papel, vidro, eletronico, outros, phone, location])

    return (
        <Container>
            <Header>
                <TextHeader>
                    Cadastrar Ecopontos
                </TextHeader>
            </Header>
            <section className="division-form-map">
                <form onSubmit={handleSubmit} className="dx-card form-register-eco">
                    <FormEcoPoint>
                        <div className="divisions">
                            <InputFormMask htmlFor='cep' label='CEP:' mask='99999-999' required={true} autoFocus={true} type='text' name='cep' value={cep} onChange={(e) => { setCep(e.target.value.replace(/\D/g, "")) }} />
                            <InputForm htmlFor='street' label='Logradouro (rua, etc.):' type='text' name='street' required={true} value={street} onChange={(e) => { setStreet(e.target.value) }} />
                            <InputForm htmlFor='number' label='Número:' type='text' name='number' required={true} value={number} onChange={(e) => { setNumber(e.target.value) }} />
                        </div>
                        <div className="divisions">
                            <InputForm htmlFor='district' label='Bairro:' required={true} type='text' name='district' value={district} onChange={(e) => { setDistrict(e.target.value) }} />
                            <InputForm htmlFor='city' label='Cidade:' type='text' name='city' required={true} value={city} onChange={(e) => { setCity(e.target.value) }} />
                            <InputForm htmlFor='state' label='Estado:' type='text' name='state' required={true} value={state} onChange={(e) => { setState(e.target.value) }} />
                        </div>
                        <section>
                            <div className="div1">
                                <InputForm htmlFor='name' label='Nome do Ecoponto:' name='name' id='name' required={true} type='text' onChange={(e) => { setName(e.target.value) }} />
                            </div>
                            <div className="div2">
                                <InputFormMask htmlFor='phone' label='Contato:' mask='(99)99999-9999' name='phone' required={false} type='text' onChange={(e) => { setPhone(e.target.value) }} />
                            </div>
                        </section>
                        <InputForm htmlFor='obs' label='Observação (opcional):' name='obs' type='text' value={obs} onChange={(e) => { setObs(e.target.value) }} />
                    </FormEcoPoint>
                    <p className="title-categories">
                        Selecione as categorias de resíduos a serem coletados em seu Ecoponto:
                    </p>
                    <CatEcoPoints>
                        <div className="cats">
                            <p>Metal</p>
                            <img src={catMetal} alt="Metal" />
                            <CheckBox className="check-categories" value={metal} onValueChange={() => { setMetal(!metal) }} />
                        </div>
                        <div className="cats">
                            <p>Papel</p>
                            <img src={catPapel} alt="Papel" />
                            <CheckBox className="check-categories" value={papel} onValueChange={() => { setPapel(!papel) }} />
                        </div>
                        <div className="cats">
                            <p>Vidro</p>
                            <img src={catVidro} alt="Vidro" />
                            <CheckBox className="check-categories" value={vidro} onValueChange={() => { setVidro(!vidro) }} />
                        </div>
                        <div className="cats">
                            <p>Plástico</p>
                            <img src={catPlastico} alt="Plástico" />
                            <CheckBox className="check-categories" value={plastico} onValueChange={() => { setPlastico(!plastico) }} />
                        </div>
                        <div className="cats">
                            <p>Eletroeletrônico</p>
                            <img src={catElet} alt="Eletroeletrônicos" />
                            <CheckBox className="check-categories" value={eletronico} onValueChange={() => { setEletronico(!eletronico) }} />
                        </div>
                        <div className="cats">
                            <p>Outros</p>
                            <img src={catOutros} alt="Outros" />
                            <CheckBox className="check-categories" value={outros} onValueChange={() => { setOutros(!outros) }} />
                        </div>
                    </CatEcoPoints>
                    <div className="button-form-eco">
                        {/* <Button className='button-submit' icon="icon ion-md-home" useSubmitBehavior={true} text="Cadastrar" type="success" /> */}
                        <Button className='button-submit' disabled={loading} type='success' useSubmitBehavior={true} style={{ padding: '0' }} >
                            <ButtonOptions width={120} style={{ backgroundColor: 'transparent', fontFamily: 'Jost', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                                <span className='dx-button-text' style={{ backgroundColor: 'transparent', textAlign: 'center', color: '#fff' }}>
                                    {
                                        loadingIndicator ?
                                            <LoadIndicator width={'24px'} height={'24px'} visible={true} /> :
                                            'Cadastrar'
                                    }
                                </span>
                            </ButtonOptions>
                        </Button>
                    </div>
                </form>
                {
                    value
                        ?
                        <div className="maps">
                            <div className="title-map"><span>O endereço está correto?</span> Se não, arraste o marcador para a posição correta.</div>
                            <aside className="dx-card">
                                <MapsGoogleRegisterEco position={location} setPosition={setLocation} height='40vh' zoom={18} />
                            </aside>
                            <aside className="dx-card">
                                <MapsGoogleRegisterEcoStreetView position={location} />
                            </aside>
                        </div>
                        :
                        <div className="dx-card no-maps">
                            <FaMapMarkedAlt color="#1C4738" fontSize='10rem' />
                            <p>Digite o endereço <span>completo</span> para que ele possa ser exibido em nosso mapa</p>
                        </div>
                }
            </section>
            <Toast
                visible={toast}
                type={type}
                onHiding={onCloseToast}
                contentComponent={() => RenderToast(situation)}
                displayTime={4000}
                height='85px'
                animation={{
                    hide: { type: 'slide', duration: 400, to: { position: { my: 'top', at: 'bottom', of: window } } },
                    show: { type: 'slide', duration: 400, from: { position: { my: 'top', at: 'bottom', of: window } } }
                }}
            />
            {
                loading &&
                <LoadTrash />
            }
        </Container>
    );
}

function RenderToast(situation) {
    return (
        <>
            {
                situation === 'categoryFail' &&
                <>
                    <BsFillExclamationCircleFill color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
                    <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Selecione ao menos uma categoria</p>
                </>
            }
            {
                situation === 'registerEcoFail' &&
                <>
                    <IoMdCloseCircle color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
                    <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Falha ao cadastrar Ecoponto.</p>
                </>
            }
            {
                situation === 'registerEcoSuccess' &&
                <>
                    <BsCheckCircleFill color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
                    <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>Ecoponto cadastrado com sucesso.</p>
                </>
            }
            {
                situation === 'cepFail' &&
                <>
                    <IoMdCloseCircle color='#FFFFFF' style={{ fontSize: '60px', marginRight: '15px' }} />
                    <p style={{ fontSize: '20px', fontFamily: 'Jost', fontWeight: '400' }}>CEP não encontrado. Digite o endereço.</p>
                </>
            }
        </>
    )
}



