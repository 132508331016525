import api from '../services/api';

export async function signIn(email, password) {
  try {
    // Send request
    const response = await api.post('/sessions', {
      email,
      password,
    });
    
    if(!response){
      throw new Error('Login inválido');
    }

    const { token, user, refresh_token } = response.data;

    sessionStorage.setItem('@Uzeh:user', JSON.stringify(user))
    sessionStorage.setItem('@Uzeh:token' , token)
    sessionStorage.setItem('@Uzeh:refresh_token' , refresh_token)
    api.defaults.headers.authorization = `Bearer ${refresh_token}`;
    
    return {
      isOk: true,
      data: {user, token}
    };

  }
  catch {
    return {
      isOk: false,
      message: "Falha na autenticação"
    };
  }
}

export async function getUser() {
  const user = sessionStorage.getItem('@Uzeh:user');
  const token = sessionStorage.getItem('@Uzeh:token');
  const refresh_token = sessionStorage.getItem('@Uzeh:refresh_token');
  try {
    if(user){
      const userParse = JSON.parse(user);
      const tokenParse = refresh_token;
      return {
        isOk: true,
        data: {
          id: userParse.id,
          email: userParse.email,
          token: tokenParse,
          name: userParse.name,
          photo: userParse.photo,
          fullname: userParse.fullname,
          user_type_id: userParse.user_type_id,
          phone: userParse.phone
        }
      };
    } 
    return {
      isOk: false,
    };
  }
  catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email, name, lastname, password, phone) {
  try {
    // Send request
    const response = await api.post('/users', {
      email,
      name,
      lastname,
      password,
      phone,
      origin: "web",
      user_type_id: 3
    })

    if(!response){
      throw new Error('Falha na criação de cadastro');
    }

    console.log('email: ', email, 'senha: ', password, 'nome: ', name, 'sobrenome: ', lastname);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Falha ao criar um novo cadastro."
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Falha ao criar uma nova senha."
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
