import styled from "styled-components";

export const Container = styled.div`
    padding: 10px 20px;

    p {
        margin: 0;
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 2rem;
        color: #1C4738;
    }
`;