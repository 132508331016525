import { Container, Active, Content, ContentModal, RowModal } from './styles.js';
import defaultUser from '../../assets/defaultuser.png';
import { useEffect, useState } from 'react';
import { Popup } from 'devextreme-react';
import img from '../../assets/defaultuser.png';
import ScrollView from 'devextreme-react/scroll-view';
import { MapsGoogleAll } from '../../components/maps-google-all';

export function LastGeneratorAndColector({ title, lastList }) {

    const [openModal, setOpenModal] = useState(false);
    const [userSelect, setUserSelect] = useState([]);

    function details(ge) {
        setOpenModal(true);
        setUserSelect(ge)
    }

    function onCloseModal() {
        setOpenModal(false);
    }

    return (
        <Container className='dx-card'>
            <h4>{title}</h4>
            {lastList && lastList.map((ge) => (
                <>
                    <div className='card-users' onClick={() => details(ge)}>
                        <div>
                            <header>
                                <p>{ge.name}</p>
                                <Active active={ge.active}>
                                    {ge.active === 1 ? 'Ativo' : 'Inativo'}
                                </Active>
                            </header>
                            <Content>
                                {
                                    (ge.district && ge.city && ge.state) ?
                                        <p className='address-last-users'>{`${ge.district}, ${ge.city}/${ge.state}`}</p> :
                                        <p className='address-last-users'>Cadastro incompleto</p>
                                }
                                <p className='date-created-last-users'>{`- ${ge.date}`}</p>
                            </Content>
                        </div>
                        <div className="content-photo">
                            <img src={ge.photo === null ? defaultUser : ge.photo} alt="user" />
                        </div>
                    </div>
                    <Popup
                        visible={openModal}
                        closeOnOutsideClick={true}
                        showCloseButton={true}
                        onHiding={onCloseModal}
                        container=".dx-viewport"
                        shadingColor="rgba(0,0,0,0.2)"
                        title='Detalhes do usuário'
                        width={userSelect.address !== "undefined, undefined, undefined, undefined, undefined" ? '85vw' : '50vw' }
                        height={userSelect.address !== "undefined, undefined, undefined, undefined, undefined" ? '90vh' : '55vh'}
                    >
                        <ScrollView>
                            <ContentModal>
                                <div style={{ width: '50%' }}>
                                    <RowModal>
                                        <p>ID: <span>{userSelect.id}</span></p>
                                        <p>NOME: <span>{` ${userSelect.fullname}`}</span></p>
                                        <p>E-MAIL: <span>{` ${userSelect.email}`}</span></p>
                                        <p>CONTATO: <span>{` ${userSelect.phone === null ? 'Não informado' : userSelect.phone}`}</span></p>
                                        <p>CPF: <span>{` ${userSelect.document === null ? 'Não informado' : userSelect.document}`}</span></p>
                                        <p>TIPO: <span>{` ${userSelect.typeName}`}</span></p>
                                        <p>DATA DE CRIAÇÃO: <span>{userSelect.date}</span></p>
                                        <p>STATUS: <span>{userSelect.active === 1 ? 'Ativo' : 'Inativo'}</span></p>
                                        <p>ENDEREÇO: <span>{userSelect.address === 'undefined, undefined, undefined, undefined, undefined' ? 'Não informado' : userSelect.address}</span></p>
                                        <p>DATA DE NASCIMENTO: <span>{userSelect.birth}</span></p>
                                        <p>ORIGEM DO CADASTRO: <span>{userSelect.origin === 'web' ? 'Web' : 'Celular'}</span></p>
                                    </RowModal>
                                </div>
                                <div className='div-photo' style={{ width: '50%' }}>
                                    <img className='user-avatar' src={userSelect.photo === null ? img : userSelect.photo} alt="Avatar User" />
                                </div>
                            </ContentModal>
                            <div>
                            {
                                userSelect.address !== "undefined, undefined, undefined, undefined, undefined" &&
                                <div style={{ paddingRight: "0.7rem" }}>
                                    <MapsGoogleAll position={userSelect} />
                                </div>
                            }
                            </div>
                        </ScrollView>
                    </Popup>
                </>
            ))}
        </Container>
    );
}