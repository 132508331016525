import { useEffect, useState } from 'react';
import { Column, SearchPanel } from 'devextreme-react/data-grid';

import api from '../../services/api';

import { 
    Container, 
    PhotoPartner, 
    GridPartner 
} from './styles';

import { HeaderText } from '../../components/header-text';
import { LoadTrash } from '../../components/load-trash';

export function ListPartner() {

    const [partner, setPartner] = useState([]);
    const [loading, setLoading] = useState(false);

    async function loadData() {
        setLoading(true)
        await api.get('/partners').then(res => {
            const data = res.data.map(part => {
                return {
                    id: part.id,
                    name: part.name,
                    description: part.description,
                    user: part.user_id,
                    photo: part.url_logo
                }
            })
            setPartner(data)
        })
        setLoading(false)
    }

    useEffect(() => { loadData() }, [])

    function photoRender(data) {
        return <PhotoPartner src={data.value} />
    }

    return (
        <Container>
            <HeaderText text='Listagem de Parceiros' />
            {loading &&
                <LoadTrash />
            }
            {!loading &&
                <GridPartner
                    dataSource={partner}
                    columnHidingEnabled={true}
                >
                    <SearchPanel visible={true} />
                    <Column caption='ID' alignment='center' dataField='id' width={70} />
                    <Column caption='NOME' alignment='center' dataField='name' width={250} />
                    <Column caption='DESCRIÇÃO' alignment='center' dataField='description' />
                    <Column caption='LOGO' alignment='center' dataField='photo' cellRender={photoRender} width={130} />
                </GridPartner>
            }
        </Container>
    );
}