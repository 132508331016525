import styled from "styled-components";

export const Container = styled.div`
    padding: 0 20px;
    width: 100%;
    margin-right: 20px;
`;

export const Title = styled.p`
    margin: 10px;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #1C4738;
`;