import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import {
  Container,
  ContentCards,
  ContentChart,
  ContentHeader,
  TextHeaderMap,
  ButtonSelectMap,
  ContentMap,
  ContentLastUsersCollects,
} from './styles';

import { CardInfo } from '../../components/card-home';
import { LastGeneratorAndColector } from '../../components/LastGeneratorAndColector';
import { Chart1 } from '../../components/graph1';
import { Chart2 } from '../../components/graph2';
import { MapsGoogle } from '../../components/maps-google';
// import { LoadComponent } from '../../components/LoadComponent';
import { HeaderText } from '../../components/header-text';
import { LoadTrash } from '../../components/load-trash';

export function Home() {
  const [som, setSom] = useState();
  const [users, setUsers] = useState([]);
  const [collect, setCollect] = useState([]);
  const [ecopoint, setEcopoint] = useState([]);
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState(0);
  const mes = new Date().getMonth();
  const ano = new Date().getFullYear();
  const [dataGrafico, setDataGrafico] = useState();
  const [type, setType] = useState('todos');
  const [markFilter, setMarkFilter] = useState([]);
  const [generator, setGenerator] = useState([]);
  const [colector, setColector] = useState([]);
  const history = useHistory();

  async function loadData() {
    setLoading(true);
    await api.get('/adm/users?address=1').then(res => {
      const data = res.data.map(us => {
        return {
          id: us.id,
          name: us.name,
          fullname: us.fullname,
          email: us.email,
          phone:us.phone,
          photo: us.photo,
          document: us.document,
          active: us.active,
          type: us.user_type_id,
          typeName: us.user_type.type,
          origin: us.origin,
          created: us.created_at,
          birth: format(new Date(us.birth), 'dd/MM/yyyy', { locale: ptBR }) ,
          date: format(new Date(us.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
          dateSepare: {
            day: format(new Date(us.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }).split('/')[0],
            month: format(new Date(us.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }).split('/')[1],
            year: format(new Date(us.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }).split('/')[2]
          },
          address: `${us.address?.street}, ${us.address?.number}, ${us.address?.district}, ${us.address?.city}, ${us.address?.state}`,
          lat: us.address?.lat,
          lng: us.address?.long,
          district: us.address?.district,
          city: us.address?.city, 
          state: us.address?.state,
          typeColor: (us.user_type_id === 5 || us.user_type_id === 3) ? "gerador" : ((us.user_type_id === 4) ? "coletor" : "admin")
        }
      }) 
      console.log(res.data[0])
      setColector(data.filter(us => {
        return us.type === 4
      }).sort(function (a, b) {
        if (a.created < b.created) {
          return 1;
        }
        if (a.created > b.created) {
          return -1;
        }
        return 0;
      }).slice(0, 5))

      setGenerator(data.filter(us => {
        return us.type === 5
      }).sort(function (a, b) {
        if (a.created < b.created) {
          return 1;
        }
        if (a.created > b.created) {
          return -1;
        }
        return 0;
      }).slice(0, 5))

      setUsers(data)
    })

    await api.get('/adm/collections').then(res => {
      const data = res.data.map(col => {
        return {
          id: col.id,
          type: col.type,
          shift: col.shift,
          status: col.status,
          note: col.note,
          user: col.user.fullname,
          address: `${col.address.street}, ${col.address.number}, ${col.address.district}, ${col.address.city}, ${col.address.state}`,
          quantity: col.quantity,
          lat: col.address.lat,
          lng: col.address.long,
          image: col.images,
          typeColor: "coleta",
        }
      })
      setCollect(data)
      // console.log("res collection",collect)
    })

    await api.get('/ecopoints').then(res => {
      const data = res.data.map(eco => {
        return {
          id: eco.id,
          status: eco.status,
          type: eco.type,
          size: eco.size,
          phone: eco.phone,
          obs: eco.obs,
          status: eco.status,
          categories: eco.categories,
          address: `${eco.address.street}, ${eco.address.number}, ${eco.address.district}, ${eco.address.city}, ${eco.address.state}`,
          lat: eco.address.lat,
          lng: eco.address.long,
          name: eco.name,
          photo: eco.photos,
          typeColor: "ecoponto"
        }
      })
      setEcopoint(data);
    })

    setLoading(false);
  }

  const mark = useMemo(() => {
    return [
      ...users,
      ...collect,
      ...ecopoint
    ]
  }, [users, collect, ecopoint]);

  useEffect(() => {
    if (mark) {
      setMarkFilter(mark)
    }
  }, [mark])

  useEffect(() => {
    loadData()
    function _numDias() {
      var objData = new Date(),
        numAno = objData.getFullYear(),
        numMes = objData.getMonth() + 1,
        numDias = new Date(numAno, numMes, 0).getDate();
      return numDias;
    }
    setDays(_numDias())
  }, [])

  const typeMonth = [
    { value: 'colector', name: 'Coletor' },
    { value: 'generator', name: 'Gerador' },
    { value: 'ecopoint', name: 'Ecoponto' },
  ]

  useEffect(() => {
    let daysMonth = []

    for (let i = 1; i <= days; i++) {
      daysMonth.push(i);
    }

    let date = new Date();
    let month = String(date.getMonth() + 1)

    const soma = daysMonth.map(day => {
      const colector = users.reduce((acc, user) => {
        return (
          Number(user.dateSepare.day) === day &&
          user.type === 4 &&
          Number(user.dateSepare.month) === Number(month)
        ) ? acc + 1 : acc
      }, 0)

      const generator = users.reduce((acc, user) => {
        return (
          day === Number(user.dateSepare.day) &&
          user.type === 5 &&
          Number(user.dateSepare.month) === Number(month)
        ) ? acc + 1 : acc
      }, 0)
      const ecopoint = users.reduce((acc, user) => {
        return (
          day === Number(user.dateSepare.day) &&
          user.type === 3 &&
          Number(user.dateSepare.month) === Number(month)
        ) ? acc + 1 : acc
      }, 0)
      return {
        days: day,
        colector,
        generator,
        ecopoint,
      }
    })

    setSom(soma)

  }, [users]);

  useEffect(() => {
    if (users) {
      DataGraphicsAcc();
    }
  }, [users]);

  const months = [
    'JANEIRO',
    'FEVEREIRO',
    'MARÇO',
    'ABRIL',
    'MAIO',
    'JUNHO',
    'JULHO',
    'AGOSTO',
    'SETEMBRO',
    'OUTUBRO',
    'NOVEMBRO',
    'DEZEMBRO'
  ];

  let monthNow = new Date();

  const DataGraphicsAcc = useCallback(() => {
    let mesAt = mes < 11 ? (mes + 1) * -1 : 0;
    let anoAt = ano;

    if (mesAt < 0) {
      mesAt = mesAt * (-1);
      anoAt = anoAt - 1;
    }

    let colector = 0;
    let generator = 0;
    let ecopoint = 0;
    const dataMM = months.map(() => {
      const somColector = users.reduce((acc, d) => {
        return (
          mesAt === (Number(d.dateSepare.month) - 1) &&
          anoAt === Number(d.dateSepare.year.split(' ')[0]) &&
          d.type === 4
        ) ? acc + 1 : acc
      }, 0);

      const somGenerator = users.reduce((acc, d) => {
        return (
          mesAt === (Number(d.dateSepare.month) - 1) &&
          anoAt === Number(d.dateSepare.year.split(' ')[0]) &&
          d.type === 5
        ) ? acc + 1 : acc
      }, 0);

      const somEcopoint = users.reduce((acc, d) => {
        return (
          mesAt === (Number(d.dateSepare.month) - 1) &&
          anoAt === Number(d.dateSepare.year.split(' ')[0]) &&
          d.type === 3
        ) ? acc + 1 : acc
      }, 0);

      const name = mesAt;

      if (mesAt === 11) {
        anoAt = anoAt + 1;
        mesAt = 0;
      } else {
        mesAt = mesAt + 1;
      }

      colector += somColector;
      generator += somGenerator;
      ecopoint += somEcopoint;

      return {
        month: months[name],
        colector,
        generator,
        ecopoint,
      }
    })
    // console.log("dataMM", dataMM)
    setDataGrafico(dataMM)
  }, [mes, ano, users, months])

  function usersActive(obj) {
    if (obj.active === 1) {
      return obj;
    }
  }

  function userActiveTypeGenerator(obj) {
    if (obj.type === 5) {
      return obj
    }
  }

  function userActiveTypeCollector(obj) {
    if (obj.type === 4) {
      return obj
    }
  }

  function usersInactive(obj) {
    if (obj.active === 0) {
      return obj;
    }
  }

  function userInactiveTypeGenerator(obj) {
    if (obj.type === 5) {
      return obj
    }
  }

  function userInactiveTypeCollector(obj) {
    if (obj.type === 4) {
      return obj
    }
  }

  const ativos = users.filter(usersActive)

  const ativosGerador = ativos.filter(userActiveTypeGenerator)

  const ativosColetor = ativos.filter(userActiveTypeCollector)

  const inativos = users.filter(usersInactive)

  const inativosGerador = inativos.filter(userInactiveTypeGenerator)

  const inativosColetor = inativos.filter(userInactiveTypeCollector)

  function typeCollectDonate(obj) {
    if (obj.type === 'doar') {
      return obj
    }
  }

  function typeCollectSale(obj) {
    if (obj.type !== 'doar') {
      return obj
    }
  }

  function statusCollect(obj) {
    if (obj.status === 'finalizada') {
      return obj
    }
  }

  function ecoInactive(obj) {
    if (obj.status === 0) {
      return obj
    }
  }

  function ecoActive(obj) {
    if (obj.status === 1) {
      return obj
    }
  }

  const qtdEcoInactive = ecopoint.filter(ecoInactive);

  const qtdEcoActive = ecopoint.filter(ecoActive);

  const typeDonate = collect.filter(typeCollectDonate)

  const typeSale = collect.filter(typeCollectSale)

  const collectStatus = collect.filter(statusCollect)

  const totalSacos = collectStatus.reduce((a, b) => {
    return Number(a) + Number(b.quantity);
  }, 0)

  const handleType = useCallback((t) => {
    setType(t)
    if (t !== 'todos') {
      const f = mark.filter(m => {
        return m.typeColor === t
      })
      setMarkFilter(f)
      console.log(f)
    } else {
      setMarkFilter(mark)
    }
  }, [mark])

  return (
    <Container>
      <HeaderText text='Início' />
      {
        loading &&
        <LoadTrash />
      }
      {
        !loading &&
        <>
          <ContentCards>
            <CardInfo
              title="USUÁRIOS ATIVOS"
              info={ativos.length}
              type={1}
              info1="GERADORES:"
              info2={ativosGerador.length}
              info3="COLETORES:"
              info4={ativosColetor.length}
              onClick={() => {history.push('/users')}}
            />
            <CardInfo
              title="USUÁRIOS INATIVOS"
              info={inativos.length}
              type={2}
              info1="GERADORES:"
              info2={inativosGerador.length}
              info3="COLETORES:"
              info4={inativosColetor.length}
            />
            <CardInfo
              title="DESCARTES SOLICITADOS"
              info={collect.length}
              type={3}
              info1="DOAÇÕES:"
              info2={typeDonate.length}
              info3="VENDAS:"
              info4={typeSale.length}
              onClick={() => {history.push('/collects')}}
            />
            <CardInfo
              title="DESCARTES FINALIZADOS"
              info={collectStatus.length}
              type={4}
              info1="PESAGEM INFORMADA:"
              info2={totalSacos}
              info3=""
              info4=""
            />
            <CardInfo
              title="ECOPONTOS"
              info={ecopoint.length}
              type={5}
              info1="ATIVOS:"
              info2={qtdEcoActive.length}
              info3="INATIVOS:"
              info4={qtdEcoInactive.length}
              onClick={() => {history.push('/listecopoints')}}
            />
          </ContentCards>
          <ContentChart>
            <Chart1
              title={`CADASTRO DE USUÁRIOS POR DIA (${months[monthNow.getMonth()]} DE ${ano})`}
              dataSource={som}
              bottonChart="days"
              values={typeMonth}
            />
            <Chart2
              title={`ACUMULADO DE CADASTRO DOS ÚLTIMOS 12 MESES`}
              dataSource={dataGrafico}
              bottonChart="month"
              values={typeMonth}
            />
          </ContentChart>
          <ContentMap>
            <ContentHeader>
              <TextHeaderMap>Mapa de localização</TextHeaderMap>
              <div>
                <ButtonSelectMap text='todos' type={type === 'todos' ? 'default' : 'normal'} onClick={() => { handleType('todos') }} />
                {/* <ButtonSelectMap text='coletores' title={'coletor'} type={type === 'coletor' ? 'default' : 'normal'} onClick={() => { handleType('coletor') }} /> */}
                <ButtonSelectMap text='geradores' title={'gerador'} type={type === 'gerador' ? 'default' : 'normal'} onClick={() => { handleType('gerador') }} />
                <ButtonSelectMap text='coletas' title={'coleta'} type={type === 'coleta' ? 'default' : 'normal'} onClick={() => { handleType('coleta') }} />
                <ButtonSelectMap text='ecopontos' title={'ecoponto'} type={type === 'ecoponto' ? 'default' : 'normal'} onClick={() => { handleType('ecoponto') }} />
              </div>
            </ContentHeader>
            <MapsGoogle position={markFilter} />
          </ContentMap>
          <ContentLastUsersCollects>
            <LastGeneratorAndColector title={"Novos Geradores"} lastList={generator} />
            <LastGeneratorAndColector title={"Novos Coletores"} lastList={colector} />
          </ContentLastUsersCollects>
        </>
      }
    </Container>
  )
}